<template>
  <div data-app>
    <v-form
      class="form mt-0 mt-lg-10"
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <div
        v-if="this.edittasks"
        class="page-title pageheading"
        style="height: 50px"
      >
        <span class="float-right green-btn">
          <a
            v-if="isEditAble"
            class="btn btn-success mr-3"
            @click="editClientinfo"
          >
            <i class="far fa-eye edit_btn"></i><span>Edit</span></a
          >
          <a v-else class="btn btn-success mr-3" @click="submit">
            <i class="far fa-eye edit_btn"></i
            ><span
              >Update
              <span v-if="isSubmitting">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular> </span></span
          ></a>
        </span>
      </div>
      <v-row class="p-2">
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.dueDate.$error }"
        >
          <label> Due Date </label> <span class="red"> *</span>
          <date-picker
            v-model.trim="$v.form.dueDate.$model"
            ref="dp1"
            @focusin.native="onfocusin"
            :disabled="isEditAble"
            placeholder="Choose Due Date"
            :config="datePickerOptions"
          ></date-picker>
          <div class="invalid-feedback" v-if="$v.form.dueDate.$error">
            <span v-if="$v.form.dueDate.$error">Due Date is required</span>
          </div>
        </div>
        <div  class="form-group col-md-6 mb-form"></div>
        <!-- <div class="form-group col-md-6 mb-form"> 
          <label> Assigned </label> <span class="red"> *</span>
          <b-form-select
          v-if="isEditAble"
            class="form-control"
            v-model="form.otherStaffId"
            :disabled="isEditAble"
            :rules="['Required']"
            value-field="id"
            text-field="fullName"
            required
            :options="otherStaffList"
          ></b-form-select>

 <model-list-select
              v-else
              :list="otherStaffList" 
              v-model="form.otherStaffId"
              option-value="id"
              option-text="fullName"
              placeholder="Assigned"
            >
            </model-list-select>
          
        </div> -->
        <!-- 
        <div v-else class="form-group col-md-6 mb-form">
          <label> Client </label> <span class="red"> *</span>
          <b-form-select
            class="form-control"
            v-model="form.clientId"
            :disabled="isEditAble"
            :rules="['Required']"
            value-field="id"
            text-field="name"
            required
            :options="ClientsList"
          ></b-form-select>
        </div> -->

        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.subject.$error }"
        >
          <v-text-field
            v-model="form.subject"
            :disabled="isEditAble"
            label="Subject"
            maxlength="50"
            ><template #label>
              Subject
              <span class="red"> *</span></template
            ></v-text-field
          >
          <div class="invalid-feedback" v-if="$v.form.subject.$error">
            <span v-if="$v.form.subject.$error">Subject is required</span>
          </div>
        </div>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.status.$error }"
        >
          <label> Status </label><span class="red"> *</span>
          <b-form-select
            class="form-control"
            v-model="form.status"
            :rules="['Required']"
            :disabled="isEditAble"
            required
            :options="status"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.status.$error">
            <span v-if="$v.form.status.$error">Status is required</span>
          </div>
        </div>
        <v-col cols="12" sm="6">
          <h3>Reminder</h3>
          <v-radio-group
            :disabled="isEditAble"
            v-model="form.isSetReminder"
            row
          >
            <v-radio label="No Reminder" :value="false"></v-radio>
            <v-radio label="Set Reminder" :value="true"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6">
          <label> Reminder Date</label>
          <div class="input-group-prepend">
            <date-picker
              v-if="!form.isSetReminder"
              v-model="form.reminderDateTime"
              ref="dp1"
              disabled
              @focusin.native="onfocusin"
              placeholder="Choose Reminder Date"
              :config="ReminderPickerOptions"
            ></date-picker>
            <date-picker
              v-else
              v-model="form.reminderDateTime"
              :disabled="isEditAble"
              ref="dp1"
              @focusin.native="onfocusin"
              placeholder="Choose Reminder Date"
              :config="ReminderPickerOptions"
            ></date-picker>

            <button
              class="btn btn-outline-primary"
              type="button"
              :disabled="isEditAble"
              @click="$refs.dp1.dp.show()"
            >
              <i class="fas fa-calendar"></i>
            </button>
            <button
              class="btn btn-outline-primary"
              type="button"
              :disabled="isEditAble"
              @click="$refs.dp1.dp.show()"
            >
              <i class="fa fa-clock"></i>
            </button>
          </div>
        </v-col>
        <div class="form-group col-md-12 mb-form">
          <label> Associated Party</label> 
          <!-- <multiselect
            v-model="form.AssociatedParties"
            placeholder="Associated Party"
            label="name"
            :disabled="isEditAble"
            track-by="id"
            :options="AssocationParties"
            :multiple="true"
          ></multiselect> -->
          
          <multi-list-select
            :list="AssocationParties"
            option-value="id"
            option-text="name"
            :selected-items="AssociatedPartiesList"
            placeholder="select item" 
            :isDisabled="isEditAble"
               @select="onSelectlist"
          >
          </multi-list-select>
        </div>

        <v-col cols="12" sm="12">
          <v-textarea
            filled
            v-model="form.comment"
            auto-grow
            :disabled="isEditAble"
            label="Comments"
            rows="4"
            row-height="30"
            maxlength="250"
            shaped
          ></v-textarea>
        </v-col>
      </v-row>
      <v-col v-if="!this.edittasks" class="d-flex mt-4" cols="12" sm="6">
        <v-btn @click="submit">
          submit
          <span v-if="isSubmitting">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span> </v-btn
      ></v-col>
    </v-form>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

import { MultiListSelect ,ModelListSelect} from "vue-search-select";
var today = new Date();
export default {
  components: {
    Multiselect,
    MultiListSelect,ModelListSelect
  },
  mixins: [validationMixin],
  validations: {
    form: {
      dueDate: { required },
      subject: { required },
      status: { required },
    },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  mounted() {
    if (this.edittasks) {
      this.form = this.edittasks;
      this.form.dueDate = this.dobformat(this.form.dueDate);
      this.form.otherStaffId = this.form.otherStaffId;
      if (this.form.reminderDateTime) {
        this.form.reminderDateTime = this.dobformat(this.form.reminderDateTime);
      }
      this.form.AssociatedParties = [];
      this.form.taskAssociatedParties.forEach((e) => {
        if (e.clientName.trim()) {
          this.form.AssociatedParties.push({
            id: e.clientId,
            name: e.clientName.trim(),
            type: "Client",
          });
        } else if (e.caregiverName.trim()) {
          this.form.AssociatedParties.push({
            id: e.caregiverId,
            name: e.caregiverName.trim(),
            type: "Caregiver",
          });
        }
      });
      this.AssociatedPartiesList=this.form.AssociatedParties
    } else {
      this.isEditAble = false;
    }
  },
  computed: {
    ...mapGetters([
      "editClient",
      "edittasks",
      "otherStaffList",
      "AssocationParties",
      "ClientsList",
      "editEmployee",
    ]),
  },
  props: {
    clientIdprop: String,
  },
  data() {
    return {
       AssociatedPartiesList: [],
      isEditAble: true,
      datePickerOptions: {
        format: "MM/DD/YYYY",
        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
      },
      ReminderPickerOptions: {
        showTodayButton: true,
        showClear: true,
        showClose: true,
        icons: {
          time: "far fa-clock",
          up: "fas fa-arrow-up",
          down: "fas fa-arrow-down",
          previous: "fas fa-chevron-left",
          next: "fas fa-chevron-right",
          clear: "far fa-trash-alt",
          close: "far fa-times-circle",
        },
        sideBySide: false,
      },

      isSubmitting: false,
      valid: true,
      form: {
        dueDate: null,
        subject: "",
        status: "",
        isSetReminder: false,
        reminderDateTime: null,
        comment: "",
        caregiverId: null,
        otherStaffId: null,
        clientId: null,
        AssociatedParties: [],
        taskAssociatedParties: "",
      },
      status: [
        { value: "", text: "Please select status" },
        { value: "Not Started", text: "Not Started" },
        { value: "Deferred", text: "Deferred" },
        { value: "Work In Progress", text: "Work In Progress" },
        { value: "Completed", text: "Completed" },
      ],
    };
  },
  methods: {
    onSelect(items, lastSelectItem) { 
      this.form.AssociatedParties = items
    },
    onSelectlist(items, lastSelectItem) { 
      this.AssociatedPartiesList=items
    },
    onfocusin() {},
    dobformat(obj) {
      if (obj != null) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    editClientinfo() {
      this.isEditAble = false;
    },
    submit() {
      this.$v.$touch();
      if (this.$refs.form.validate() && !this.$v.form.$invalid) {
        this.isSubmitting = true;
        if (this.editClient) {
          this.form.clientId = this.editClient.id;
        } else {
          this.form.caregiverId = this.editEmployee.id;
        }

        this.form.taskAssociatedParties = [];
        this.form.dueDate = new Date(this.form.dueDate);
        if (this.form.reminderDateTime) {
          this.form.reminderDateTime = new Date(this.form.reminderDateTime);
        }
        if ( this.AssociatedPartiesList) {
         this.AssociatedPartiesList.forEach((e) => {
            if (e.type == "Client") {
              this.form.taskAssociatedParties.push({ clientId: e.id });
            }
            //  else if (e.type == "Caregiver") {
            //   this.form.taskAssociatedParties.push({ caregiverId: e.id });
            // } 
            else {
              this.form.taskAssociatedParties.push({ caregiverId: e.id });
            }
          });
        } 
        if (this.form.id) {
          this.$store
            .dispatch("updateTasks", this.form)
            .then((response) => {
              if (response.message == "Success") {
                Swal.fire({
                  title: "",
                  text: "The Task has been Updated Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                this.isSubmitting = false;
                this.$emit("taskSent");
              } else if (response.message == "Failure") {
                Swal.fire({
                  title: "",
                  text: "Error!",
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
              this.isSubmitting = false;
            })
            .catch((ex) => {
              this.isSubmitting = false;
              
            });
        } else {
          this.$store
            .dispatch("saveTasks", this.form)
            .then((response) => {
              if (response.message == "Success") {
                Swal.fire({
                  title: "",
                  text: "The Task has been Updated Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                this.isSubmitting = false;
                this.$emit("taskSent");
              } else if (response.message == "Failure") {
                Swal.fire({
                  title: "",
                  text: "Error!",
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
              this.isSubmitting = false;
            })
            .catch((ex) => {
              this.isSubmitting = false;
              
            });
        }
      }
    },
  },
};
</script>

<style>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.red {
  color: red;
}
.ui.label.transition.visible {
  width: auto;
}
</style>

