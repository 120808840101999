<template>
  <div data-app>
    <v-form
      class="form mt-0 mt-lg-10"
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <h2>GENERAL INFORMATION</h2>
      <div
        v-if="this.editIntake"
        class="page-title pageheading"
        style="height: 50px"
      >
        <span class="float-right green-btn">
          <a
            v-if="isEditAble"
            class="btn btn-success mr-3"
            @click="editClientinfo"
          >
            <i class="far fa-eye edit_btn"></i><span>Edit</span></a
          >
          <a v-else class="btn btn-success mr-3" @click="submit">
            <i class="far fa-eye edit_btn"></i
            ><span
              >Update
              <span v-if="isSubmitting">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular> </span></span
          ></a>
        </span>
      </div>
      <v-row class="p-2">
        <div
          class="form-group col-md-12 mb-form"
          :class="{ invalid: $v.form.intakeType.$error }"
        >
          <label> Intake Type  </label><span class="red"> *</span>
          <b-form-select
            :disabled="isEditAble"
            class="form-control"
            v-model.trim="$v.form.intakeType.$model"
            :rules="['Required']"
            :options="intakeType"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.intakeType.$error">
            <span v-if="$v.form.intakeType.$error">Intake Type is required</span>
          </div>
        </div>

        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.gender.$error }"
        >
          <label> Gender  </label><span class="red"> *</span>
          <b-form-select
            :disabled="isEditAble"
            class="form-control"
            v-model.trim="$v.form.gender.$model"
            :rules="[(v) => !!v || 'Item is required']"
            :options="gender"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.gender.$error">
            <span v-if="$v.form.gender.$error">Gender is required</span>
          </div>
        </div>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.race.$error }"
        >
          <label>Race  </label><span class="red"> *</span>
          <b-form-select
            :disabled="isEditAble"
            class="form-control"
            v-model.trim="$v.form.race.$model"
            :rules="[(v) => !!v || 'Item is required']"
            :options="Race"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.race.$error">
            <span v-if="$v.form.race.$error">Race is required</span>
          </div>
        </div>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.primaryLanguage.$error }"
        >
          <label>Primary Language  </label><span class="red"> *</span>
          <b-form-select
            :disabled="isEditAble"
            class="form-control"
            v-model.trim="$v.form.primaryLanguage.$model"
            :rules="['Required']"
            :options="PrimaryLanguage"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.primaryLanguage.$error">
            <span v-if="$v.form.primaryLanguage.$error"
              >Primary Language is required</span
            >
          </div>
        </div>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.diagnosis.$error }"
        >
          <label>Diagnosis  </label><span class="red"> *</span>
          <b-form-select
            class="form-control"
            :disabled="isEditAble"
            :rules="['Required']"
            v-model.trim="$v.form.diagnosis.$model"
            :options="diagnosis"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.diagnosis.$error">
            <span v-if="$v.form.diagnosis.$error">Diagnosis is required</span>
          </div>
        </div>
      </v-row>
      <h3>About the Child/Consumer: <span class="red"> *</span> </h3>
      <v-row class="p-2">
        <v-col cols="12" sm="6">
          <p>Is he/she?</p>
          <v-radio-group
            :disabled="isEditAble"
            v-model="form.social"
            :rules="[(v) => !!v || 'Item is required']"
            required
          >
            <v-radio label="Social" value="1"></v-radio>
            <v-radio label="Antisocial" value="2"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6">
          <p>Any Behavioural  medication?</p>
          <v-radio-group
            :disabled="isEditAble"
            v-model="form.behavioralMedication"
            :rules="[(v) => !!v || 'Item is required']"
            required
          >
            <v-radio label="Yes" value="1"></v-radio>
            <v-radio label="No" value="2"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6">
          <p>Does he/she get agitated/anxious easily ?</p>
          <v-radio-group
            :disabled="isEditAble"
            v-model="form.agitatedOrAnxious"
            :rules="[(v) => !!v || 'Item is required']"
            required
          >
            <v-radio label="Yes" value="1"></v-radio>
            <v-radio label="No" value="2"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6">
          <p>Does he/she use a wheelchair,walker braces ?</p>
          <v-radio-group
            :disabled="isEditAble"
            v-model="form.wheelchareOrWalkerbraces"
            :rules="[(v) => !!v || 'Item is required']"
            required
          >
            <v-radio label="Yes" value="1"></v-radio>
            <v-radio label="No" value="2"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6">
          <p>Does he/she wander or run away ?</p>
          <v-radio-group
            :disabled="isEditAble"
            v-model="form.wanderOrRunaway"
            :rules="[(v) => !!v || 'Item is required']"
            required
          >
            <v-radio label="Yes" value="1"></v-radio>
            <v-radio label="No" value="2"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6">
          <p>Toileting ?</p>
          <v-radio-group
            :disabled="isEditAble"
            v-model="form.toileting"
            :rules="[(v) => !!v || 'Item is required']"
            required
          >
            <v-radio
              label="Does not need any assistance with toileting"
              value="1"
            ></v-radio>
            <v-radio label="Needs prompting" value="2"></v-radio>
            <v-radio
              label="Needs assistance with toileting"
              value="3"
            ></v-radio>
            <v-radio label="Client uses diapers" value="4"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6">
          <p>Is he/she aggressive toward others ?</p>
          <v-radio-group
            :disabled="isEditAble"
            v-model="form.aggressivetowardOthers"
            :rules="[(v) => !!v || 'Item is required']"
            required
          >
            <v-radio label="Yes" value="1"></v-radio>
            <v-radio label="No" value="2"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6">
          <p>Is he/she aggressive toward themself ?</p>
          <v-radio-group
            :disabled="isEditAble"
            v-model="form.aggressivetowardThemself"
            :rules="[(v) => !!v || 'Item is required']"
            required
          >
            <v-radio label="Yes" value="1"></v-radio>
            <v-radio label="No" value="2"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6">
          <p>Any visual impairments ?</p>
          <v-radio-group
            :disabled="isEditAble"
            v-model="form.visualImpairments"
            :rules="[(v) => !!v || 'Item is required']"
            required
          >
            <v-radio label="Yes" value="1"></v-radio>
            <v-radio label="No" value="2"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6">
          <p>Any hearing impairments ?</p>
          <v-radio-group
            :disabled="isEditAble"
            v-model="form.hearingImpairments"
            :rules="[(v) => !!v || 'Item is required']"
            required
          >
            <v-radio label="Yes" value="1"></v-radio>
            <v-radio label="No" value="2"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="12">
          <v-textarea
            filled
            maxlength = '250'
            :disabled="isEditAble"
            v-model="form.likesOrDisLikes"
            auto-grow
            label="Client's Like/Dislike"
            rows="4"
            row-height="30"
            shaped
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="12">
          <v-textarea
            filled
            auto-grow
            maxlength = '250'
            :disabled="isEditAble"
            v-model="form.nutritionalRequirements"
            label="Special Nutritional Requirements"
            rows="4"
            row-height="30"
            shaped
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="12">
          <v-textarea
            filled
            :disabled="isEditAble"
            auto-grow
            maxlength = '250'
            v-model="form.servicesRequested"
            label="Services Requested/Expectation of Caregiver"
            rows="4"
            row-height="30"
            shaped
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="12">
          <v-textarea
            filled
            v-model="form.generalRespite"
            auto-grow
            label="General Respite/PA Schedule"
            rows="4"
            row-height="30"
            maxlength = '250'
            :disabled="isEditAble"
            shaped
          ></v-textarea>
        </v-col>

        <v-col v-if="!this.editIntake" class="d-flex mt-4" cols="12" sm="6">
          <v-btn @click="submit">
            submit
            <span v-if="isSubmitting">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </span> </v-btn
        ></v-col>
      </v-row>
    </v-form>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  mixins: [validationMixin],
  validations: {
    form: {
      intakeType: { required },
      gender: { required },
      race: { required },
      primaryLanguage: { required },
      diagnosis: { required },
    },

    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  mounted() {
    this.resetForm();
    if (this.editIntake) {
      this.form = this.editIntake;
      this.form.social = this.form.social.toString();
      this.form.behavioralMedication =
        this.form.behavioralMedication.toString();
      this.form.agitatedOrAnxious = this.form.agitatedOrAnxious.toString();
      this.form.wheelchareOrWalkerbraces =
        this.form.wheelchareOrWalkerbraces.toString();
      this.form.wanderOrRunaway = this.form.wanderOrRunaway.toString();
      this.form.toileting = this.form.toileting.toString();
      this.form.aggressivetowardOthers =
        this.form.aggressivetowardOthers.toString();
      this.form.visualImpairments = this.form.visualImpairments.toString();
      this.form.aggressivetowardThemself =
        this.form.aggressivetowardThemself.toString();
      this.form.hearingImpairments = this.form.hearingImpairments.toString();
    } else {
      this.isEditAble = false;
    }
  },
  computed: {
    ...mapGetters(["editIntake",   "editClient"]),
  },
  props: {
    clientIdprop: String,
  },
  data() {
    return {
      isEditAble: true,
      isSubmitting: false,
      valid: true,
      form: {
        intakeType: "",
        gender: "",
        race: "",
        primaryLanguage: "",
        diagnosis: "",
        social: "",
        behavioralMedication: "",
        agitatedOrAnxious: "",
        wheelchareOrWalkerbraces: "",
        wanderOrRunaway: "",
        toileting: "",
        aggressivetowardOthers: "",
        aggressivetowardThemself: "",
        visualImpairments: "",
        hearingImpairments: "",
        likesOrDisLikes: "",
        nutritionalRequirements: "",
        servicesRequested: "",
        generalRespite: "",
        clientId: "",
      },
      intakeType: [
        { value: "", text: "Please Select Intake Type" },
        { value: "Initial Intake", text: "Initial Intake" },
        { value: "Reassessment", text: "Reassessment" },
      ],
      gender: [
        { value: "", text: "Please Select Gender" },
        { value: "male", text: "Male" },
        { value: "female", text: "Female" },
      ],
      Race: [
        { value: "", text: "Please Select Race" },
        { value: "Hispanic or Latino", text: "Hispanic or Latino" },
        { value: "Black or African American", text: "Black or African American" },
        { value: "Asian American", text: "Asian American" },
        { value: "White", text: "White" },
        { value: "2+ races", text: "2+ races" },
        { value: "Other", text: "Other" },
      ],
      PrimaryLanguage: [
        { value: "", text: "Please Select Primary Language" },
        { value: "English", text: "English" },
        { value: "Spanish", text: "Spanish" },
        { value: "AsianAmerican", text: "Armenian" },
        { value: "Russian", text: "Russian" },
      ],
      diagnosis: [
        { value: "", text: "Please Select Diagnosis" },
        { value: "Austim", text: "Austim" },
        { value: "Down Syndrome", text: "Down Syndrome" },
        { value: "Behavioural  Disorder", text: "Behavioural  Disorder" },
        { value: "Fetal Alcohol Syndrome", text: "Fetal Alcohol Syndrome" },
        { value: "Brain Injury", text: "Brain Injury" },
        { value: "Intellectual Disability", text: "Intellectual Disability" },
        { value: "Physical Disability", text: "Physical Disability" },
        { value: "Cerebral Palsy", text: "Cerebral Palsy" },
        { value: "Seizures", text: "Seizures" },
      ],
    };
  },
  methods: {
    editClientinfo() {
      this.isEditAble = false;
    },
    resetForm() {
      (this.form.intakeType = ""),
        (this.form.gender = ""),
        (this.form.race = ""),
        (this.form.primaryLanguage = ""),
        (this.form.diagnosis = ""),
        (this.form.social = ""),
        (this.form.behavioralMedication = ""),
        (this.form.agitatedOrAnxious = ""),
        (this.form.wheelchareOrWalkerbraces = ""),
        (this.form.wanderOrRunaway = ""),
        (this.form.toileting = ""),
        (this.form.aggressivetowardOthers = ""),
        (this.form.aggressivetowardThemself = ""),
        (this.form.visualImpairments = ""),
        (this.form.hearingImpairments = ""),
        (this.form.likesOrDisLikes = ""),
        (this.form.nutritionalRequirements = ""),
        (this.form.servicesRequested = ""),
        (this.form.generalRespite = ""),
        (this.form.clientId = "");
    },
    async submit() { 
      this.$v.$touch();
      if (this.$refs.form.validate() && !this.$v.form.$invalid) {
        this.form.clientId =  this.editClient.id;
        this.form.social = parseInt(this.form.social);
        this.form.behavioralMedication = parseInt(
          this.form.behavioralMedication
        );
        this.form.agitatedOrAnxious = parseInt(this.form.agitatedOrAnxious);
        this.form.wheelchareOrWalkerbraces = parseInt(
          this.form.wheelchareOrWalkerbraces
        );
        this.form.wanderOrRunaway = parseInt(this.form.wanderOrRunaway);
        this.form.toileting = parseInt(this.form.toileting);
        this.form.aggressivetowardOthers = parseInt(
          this.form.aggressivetowardOthers
        );
        this.form.aggressivetowardThemself = parseInt(
          this.form.aggressivetowardThemself
        );
        this.form.visualImpairments = parseInt(this.form.visualImpairments);
        this.form.hearingImpairments = parseInt(this.form.hearingImpairments);
        this.isSubmitting = true;
        if (this.form.id) {
          await this.$store
            .dispatch("updateIntake", this.form)
            .then(() => {
              this.isSubmitting = false;
              Swal.fire({
                title: "",
                text: "The Intake has been Updated Successfully!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
               this.$emit("intakeSent");
              this.resetForm;
             
            })
            .catch(() => {
              this.isSubmitting = false;
            });
          this.isEditAble = true;
        } else {
          await this.$store
            .dispatch("saveIntake", this.form)
            .then(() => {
               this.$emit("intakeSent");
              this.isSubmitting = false;
              Swal.fire({
                title: "",
                text: "The Intake has been Created Successfully!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
            })
            .catch(() => {
              this.isSubmitting = false;
            });
          this.resetForm();
         
        }
      }
    },
  },
};
</script>

<style>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
</style>

