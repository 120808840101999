<template>
  <div>
    <v-form
      class="form mt-0 mt-lg-10"
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <div
        v-if="this.editStaffing"
        class="page-title pageheading"
        style="height: 50px"
      >
        <span class="float-right green-btn">
          <a
            v-if="isEditAble"
            class="btn btn-success mr-3"
            @click="editClientinfo"
          >
            <i class="far fa-eye edit_btn"></i><span>Edit</span></a
          >
          <a v-else class="btn btn-success mr-3" @click="submit">
            <i class="far fa-eye edit_btn"></i>
            <span>
              Update
              <span v-if="isSubmitting">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </span>
            </span>
          </a>
        </span>
      </div>
      <div>
        <v-row class="p-2">
          <div
            class="form-group col-md-6 mb-form"
            :class="{ invalid: $v.form.status.$error }"
          >
            <label> Status </label><span class="red"> *</span>
            <b-form-select
              class="form-control"
              v-model.trim="$v.form.status.$model"
              :rules="['Required']"
              :disabled="isEditAble"
              required
              :options="status"
            ></b-form-select>
            <div class="invalid-feedback" v-if="$v.form.status.$error">
              <span v-if="$v.form.status.$error">Status is required</span>
            </div>
          </div>
          <v-col cols="12" sm="6"> </v-col>
          <div
            class="form-group col-md-6 mb-form"
            :class="{ invalid: $v.form.startDate.$error }"
          >
            <label> Start Date </label><span class="red"> *</span>
            <date-picker
              v-model.trim="$v.form.startDate.$model"
              ref="dp1"
              @focusin.native="onfocusin"
              placeholder="Choose Start Date"
              :disabled="isEditAble"
              @dp-change="dataStartChange"
              :config="datePickerOptions"
            ></date-picker>
            <div class="invalid-feedback" v-if="$v.form.startDate.$error">
              <span v-if="$v.form.startDate.$error"
                >Start Date is required</span
              >
            </div>
          </div>
          <div class="form-group col-md-6 mb-form">
            <label> End Date </label>
            <date-picker
              v-model="form.endDate"
              ref="dp1"
              class="dpdataEndDate"
              @focusin.native="onfocusin"
              :disabled="isEditAble"
              placeholder="Choose End Date"
              :config="datePickerOptionsEND"
            ></date-picker>
          </div>
          <div
            v-if="this.editClient"
            class="form-group col-md-12 mb-form"
            :class="{ invalid: $v.form.casegiverId.$error }"
          >
            <label>Caregiver</label><span class="red"> *</span>
            <b-form-select
              v-if="this.editClient && this.editStaffing"
              class="form-control"
              value-field="id"
              disabled
              text-field="name"
              v-model.trim="$v.form.casegiverId.$model"
              :rules="['Required']"
              required
              :options="careGiverList"
            ></b-form-select>

            <model-list-select
              v-else
              :list="UniqueEmployeesList"
              v-model.trim="$v.form.casegiverId.$model"
              option-value="id"
              option-text="name"
              required
              placeholder="Select Caregiver"
            >
            </model-list-select>
            <div class="invalid-feedback" v-if="$v.form.casegiverId.$error">
              <span v-if="$v.form.casegiverId.$error">
                Caregiver is required
              </span>
            </div>
          </div>
          <div
            v-else
            class="form-group col-md-12 mb-form"
            :class="{ invalid: $v.clientId.$error }"
          >
            <label>Client </label> <span class="red"> *</span>
            <b-form-select
              v-if="this.editEmployee && this.editStaffing"
              class="form-control"
              value-field="id"
              disabled
              text-field="name"
              v-model.trim="$v.clientId.$model"
              :rules="['Required']"
              :options="ClientsList"
            ></b-form-select>
            <model-list-select
              v-else
              :list="ClientsList"
              v-model.trim="$v.clientId.$model"
              option-value="id"
              option-text="name"
              placeholder="select Client"
            >
            </model-list-select>
            <div class="invalid-feedback" v-if="$v.clientId.$error">
              <span v-if="$v.clientId.$error">Client is required</span>
            </div>
          </div>
          <div class="form-group col-md-12 mb-form">
            <v-btn @click="addRow" :disabled="isEditAble"
              >Add Custom Rate</v-btn
            >
          </div>
          <div class="row" v-for="(item, index) in rowSubcodes" :key="index">
            <div class="row" v-if="item.payRateType == 'Custom'">
              <div class="form-group col-md-5 mb-form">
                <label>Sub Code </label><span class="red"> *</span>
                <multiselect
                  v-model="item.subCodeId"
                  placeholder="Sub Code"
                  label="subCode"
                  track-by="authorizationNumber"
                  :disabled="isEditAble || item.showEditBtn"
                  :options="subcodeListClient"
                  @open="dispatchOpen(item.subCodeId)"
                  @select="dispatchAction"
                >
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title"
                        >{{ props.option.subCode }} -Auth#
                        {{ props.option.authorizationNumber }}</span
                      >
                    </div>
                  </template>
                </multiselect>
              </div>
              <v-col cols="12" sm="2">
                <label> Caregiver Pay Rate </label>
                <b-form-select
                  class="form-control"
                  v-model="item.payRateType"
                  :rules="['Required']"
                  required
                  @change="changeRate(item)"
                  :disabled="isEditAble || item.showEditBtn || !item.subCodeId"
                  :options="rateDrpDownvalue"
                ></b-form-select>
              </v-col>
              <v-col v-if="item.payRateType == 'Default'" cols="12" sm="2">
                <v-text-field
                  disabled
                  v-model="item.payRate"
                  label="Amount"
                  maxlength="50"
                  prefix="$ "
                ></v-text-field>
              </v-col>
              <v-col v-else cols="12" sm="2">
                <v-text-field
                  v-model="item.payRate"
                  label="Amount"
                  maxlength="6"
                  prefix="$ "
                  type="number"
                  :disabled="isEditAble || item.showEditBtn"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                ></v-text-field>
              </v-col>
              <v-col v-if="item.showEditBtn && !isEditAble" cols="12" sm="1">
                <span>
                  <a class="btn btn-success mr-3" @click="editRow(index)">
                    <i class="material-icons">edit</i></a
                  >
                </span>
              </v-col>
              <v-col v-if="item.showSaveBtn && !isEditAble" cols="12" sm="1">
                <span>
                  <a class="btn btn-success mr-3" @click="saveRow(index)">
                    <i class="material-icons">save</i></a
                  >
                </span>
              </v-col>
              <v-col
                v-if="
                  item.showAddBtn &&
                  !isEditAble &&
                  subcodeListClient2.length > 0
                "
                cols="12"
                sm="1"
              >
                <span>
                  <a class="btn btn-success mr-3" @click="addRow">
                    <i class="material-icons">add</i></a
                  >
                </span>
              </v-col>
              <v-col
                v-if="
                  item.showDeleteBtn && !isEditAble && rowSubcodes.length > 0
                "
                cols="12"
                sm="1"
              >
                <span>
                  <a class="btn btn-success mr-3" @click="deleteRow(index)">
                    <i class="material-icons">delete</i></a
                  >
                </span>
              </v-col>
            </div>
          </div>
          <v-col cols="12" sm="6">
            <label for=""> Last visit date </label>
            <date-picker
              v-model="form.lastVisitDate"
              ref="dp1"
              @focusin.native="onfocusin"
              disabled
              placeholder="Choose End Date"
              :config="datePickerOptionsEND"
            ></date-picker>
          </v-col>
          <v-col cols="12" sm="12">
            <v-textarea
              filled
              v-model="form.notes"
              auto-grow
              label="Notes"
              maxlength="250"
              :disabled="isEditAble"
              rows="4"
              row-height="30"
              shaped
            ></v-textarea>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="pl-3 pt-3">
          <h2>Settings</h2>
          <v-col class="d-flex" cols="12">
            <v-checkbox
              v-model="form.isOverTimeAllowed"
              :disabled="isEditAble"
              label="Caregiver is allowed to work overtime"
            ></v-checkbox>
          </v-col>
          <v-col class="d-flex" cols="12">
            <v-checkbox
              v-model="form.isLocationExempt"
              :disabled="isEditAble"
              label="Caregiver is exempt from location based tracking"
            ></v-checkbox>
          </v-col>
          <v-col class="d-flex" cols="12">
            <v-checkbox
              v-model="form.isExceedHoursAllowed"
              :disabled="isEditAble"
              label="Caregiver can exceed client’s authorized hours per month"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-col v-if="!this.editStaffing" class="d-flex mt-4" cols="12" sm="6">
          <v-btn @click="submit">
            submit
            <span v-if="isSubmitting">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </span> </v-btn
        ></v-col>
      </div>
    </v-form>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { ModelListSelect } from "vue-search-select";
var today = new Date();

export default {
  components: {
    Multiselect,
    ModelListSelect,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      casegiverId: { required },
      status: { required },
      startDate: { required },
    },
    clientId: { required },

    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  watch: {},
  mounted() {
    this.subcodeListClient2 = this.subcodeListClient;
    // if (this.editClient) {
    //   // this.changeClient(this.editClient.id);
    //   this.disablesubCode = false;
    // }
    if (this.editStaffing) {
      this.form = this.editStaffing;
      this.form.endDate = this.dobformat(this.form.endDate);
      this.form.startDate = this.dobformat(this.form.startDate);
      if (this.form.lastVisitDate) {
        this.form.lastVisitDate = new Date(this.form.lastVisitDate);
      }
      if (this.editEmployee) {
        this.clientId = this.form.clientId;
      }
      this.form.subCodeId = [];
      this.rowSubcodes = [];
      this.form.casegiverAssociatedSubcodes.forEach((element) => {
        this.rowSubcodes.push({
          subCodeId: {
            id: element.subCodesId,
            subCode: element.subCode,
            authorizationNumber: element.authorizationNumber,
          },
          payRate: element.payRate,
          payRateType: element.payRateType,
          showAddBtn: false,
          showEditBtn: true,
          showDeleteBtn: true,
          showSaveBtn: false,
        });

        var subcodeClient = this.subcodeListClient.filter(function (e) {
          return e.id !== element.subCodesId;
        });
        this.subcodeListClient2 = subcodeClient;
      });

      if (this.subcodeListClient.length > 0) {
        this.rowSubcodes[this.rowSubcodes.length - 1].showAddBtn = true;
      }
      this.rowSubcodes[this.rowSubcodes.length - 1].showEditBtn = true;
      this.rowSubcodes[this.rowSubcodes.length - 1].showDeleteBtn = true;
    } else {
      this.isEditAble = false;
    }
  },
  computed: {
    ...mapGetters([
      "subcodeListClient",
      "UniqueEmployeesList",
      "careGiverList",
      "editClient",
      "editStaffing",
      "ClientsList",
      "editEmployee",

      "GetAuthenticatedUser",
    ]),
  },
  props: {
    clientIdprop: String,
  },
  data() {
    return {
      subcodeListClient2: [],
      disablesubCode: true,
      subCodeRow: 1,
      isEditAble: true,
      clientId: "",
      datePickerOptions: {
        format: "MM/DD/YYYY",
        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
      },
      datePickerOptionsEND: {
        format: "MM/DD/YYYY",
        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
      },
      isSubmitting: false,
      valid: true,
      rowSubcodes: [],
      form: {
        status: "",
        isOverTimeAllowed: false,
        payRateType: "Default",
        isNoteMandatory: false,
        isExceedHoursAllowed: false,
        isLocationExempt: true,
        notes: "",
        lastVisitDate: null,
        hoursTogather: 0,
        customrate: "",
        payRate: "",
        casegiverAssociatedSubcodes: [],
        casegiverId: "",
        subCodeId: "",
        endDate: null,
        startDate: null,
        caregiverId: "",
      },
      status: [
        { value: "", text: "Select status" },
        { value: "Active", text: "Active" },
        { value: "Inactive", text: "Inactive" },
      ],
      rateDrpDownvalue: [{ value: "Custom", text: "Custom Rate" }],
      selectedRow: null,
    };
  },
  methods: {
    changeRate(obj) {
      if (obj.payRateType == "Default") {
        var obj1;
        this.subcodeListClient.forEach((e) => {
          if (e.id == obj.subCodeId.id) {
            obj1 = e.caregiverRate;
          }
        });
        this.rowSubcodes.forEach((element) => {
          if (element.subCodeId.id == obj.subCodeId.id) {
            element.payRate = obj1;
          }
        });
      }
    },

    dataStartChange: (e) => {
      document
        .querySelectorAll(".dpdataEndDate")[0]
        .__vue__.dp.minDate(e.date._d);

      var endDate = new Date(
        document.querySelectorAll(".dpdataEndDate")[0].value
      );
      var startDate = new Date(e.date._d);

      if (endDate <= startDate) {
        Swal.fire({
          title: "",
          text: "The End Date is less than Start Date!",
          icon: "error",
          confirmButtonClass: "btn btn-danger",
        });
        document.querySelectorAll(".dpdataEndDate")[0].value = null;
      }
    },
    dobformat(obj) {
      if (obj != null) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    dispatchAction(obj) {
      this.rowSubcodes.forEach((element) => {
        if (element.showAddBtn || element.showSaveBtn) {
          element.payRate = obj.caregiverRate;
        }
      });
      this.subcodeListClient2 = this.subcodeListClient.filter(function (e) {
        return e.id !== obj.id;
      });
    },
    dispatchOpen(obj) {
      if (obj) {
        this.selectedRow = obj.id;
      }
    },
    addRow() {
      if (this.rowSubcodes.length > 0) {
        if (
          this.rowSubcodes[this.rowSubcodes.length - 1].payRate &&
          this.rowSubcodes[this.rowSubcodes.length - 1].subCodeId
        ) {
          this.rowSubcodes.forEach((element) => {
            element.showAddBtn = false;
            element.showEditBtn = true;
            element.showDeleteBtn = true;
          });
          var subId =
            this.rowSubcodes[this.rowSubcodes.length - 1].subCodeId.id;
          // this.subcodeListClient2 = this.subcodeListClient.filter(function (e) {
          //   return e.id !== subId;
          // });
          // if (this.subcodeListClient2.length > 0) {
          this.rowSubcodes.push({
            subCodeId: "",
            payRate: "",
            payRateType: "Custom",
            showAddBtn: true,
            showEditBtn: false,
            showDeleteBtn: false,
            showSaveBtn: false,
          });
          // }
        } else {
          Swal.fire({
            title: "",
            text: "Please Add SubCode",
            icon: "error",
            confirmButtonClass: "btn btn-danger",
          });
        }
      } else {
        this.rowSubcodes.push({
          subCodeId: "",
          payRate: "",
          payRateType: "Custom",
          showAddBtn: true,
          showDeleteBtn: true,
          showEditBtn: false,
          showSaveBtn: false,
        });
      }
    },
    editRow(obj) {
      this.rowSubcodes.forEach((element) => {
        element.showAddBtn = false;
        element.showSaveBtn = false;
        element.showEditBtn = true;
        element.showDeleteBtn = true;
      });
      var selectedRow = this.selectedRow;
      if (selectedRow) {
        var iSeXIST =
          this.rowSubcodes.filter(function (e) {
            return e.subCodeId.id == selectedRow;
          }).length > 0;
        if (iSeXIST) {
          this.selectedRow = null;
        } else {
          var subcodeClient = this.subcodeListClient.filter(function (e) {
            return e.id == selectedRow;
          });
          this.subcodeListClient2.push(subcodeClient[0]);
        }
      }
      this.rowSubcodes[obj].showEditBtn = false;
      this.rowSubcodes[obj].showDeleteBtn = false;
      this.rowSubcodes[obj].showSaveBtn = true;
    },
    deleteRow(obj) { 
      this.rowSubcodes = this.rowSubcodes.map((x, i) => {
        if (i == obj) {
          x.payRateType = "Default";
          var defaults = this.subcodeListClient.find(
            (y) => y.authorizationNumber == x.subCodeId.authorizationNumber
          );
          x.payRate = defaults.caregiverRate;
        }
        return x;
      }); 
    },
    saveRow(obj) {
      this.rowSubcodes.forEach((element) => {
        element.showAddBtn = false;
        element.showSaveBtn = false;
        element.showEditBtn = true;
        element.showDeleteBtn = true;
      });
      var selectedRow = this.selectedRow;
      if (selectedRow) {
        var iSeXIST =
          this.rowSubcodes.filter(function (e) {
            return e.subCodeId.id == selectedRow;
          }).length > 0;
        if (iSeXIST) {
          this.selectedRow = null;
        } else {
          var subcodeClient = this.subcodeListClient.filter(function (e) {
            return e.id == selectedRow;
          });
          this.subcodeListClient2.push(subcodeClient[0]);
        }
      }
      if (this.subcodeListClient2.length > 0) {
        this.rowSubcodes[this.rowSubcodes.length - 1].showAddBtn = true;
      } else {
        this.rowSubcodes[this.rowSubcodes.length - 1].showAddBtn = false;
      }
      this.rowSubcodes[this.rowSubcodes.length - 1].showEditBtn = true;
      this.rowSubcodes[this.rowSubcodes.length - 1].showDeleteBtn = true;
    },
    editClientinfo() {
      this.isEditAble = false;
    },
    onfocusin() {},
    submit() {
      this.$v.$touch(); 
      if (this.rowSubcodes.length != 0) {
        if (this.rowSubcodes[0].subCodeId == "") {
          Swal.fire({
            title: "",
            text: "Add Atleast one Subcode!",
            icon: "error",
            confirmButtonClass: "btn btn-danger",
          });
          this.rowSubcodes.pop();
        }
      }

      if (!this.$v.form.$invalid) {
        this.form.payRate = parseFloat(this.form.payRate);
        if (this.editClient) {
          this.form.clientId = this.editClient.id;
        } else {
          this.form.casegiverId = this.editEmployee.id;
        }
        if (this.clientId) {
          this.form.clientId = this.clientId;
        }
        this.form.startDate = new Date(this.form.startDate);
        this.form.startDate.setHours(this.form.startDate.getHours() + 13);
        if (this.form.endDate) {
          this.form.endDate = new Date(this.form.endDate);
          this.form.endDate.setHours(this.form.endDate.getHours() + 13);
        }
        if (this.form.lastVisitDate) {
          this.form.lastVisitDate = new Date(this.form.lastVisitDate);
          this.form.lastVisitDate.setHours(
            this.form.lastVisitDate.getHours() + 13
          );
        }
        this.form.casegiverAssociatedSubcodes = [];
        this.rowSubcodes.forEach((element) => {
          if (element.subCodeId != "" && element.payRateType != "") {
            this.form.casegiverAssociatedSubcodes.push({
              SubCodesId: element.subCodeId.id,
              PayRateType: element.payRateType,
              PayRate: parseFloat(element.payRate),
              AuthorizationNumber: element.subCodeId.authorizationNumber,
            });
          }
        });
         this.isSubmitting = true;

        this.form.modifiedBy = this.GetAuthenticatedUser.userName;
        if (this.form.id) {
          this.$store
            .dispatch("updateStaffing", this.form)
            .then((response) => {
              if (response.message == "Success") {
                Swal.fire({
                  title: "",
                  text: "This staffing has been updated successfully",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
              } else if (response.message == "Failure") {
                Swal.fire({
                  title: "",
                  text: response.validationMessage,
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
              this.isSubmitting = false;
              this.$emit("staffingSent");
              this.isSubmitting = false;
            })
            .catch((ex) => {
              this.isSubmitting = false;
            });
        } else {
          this.$store
            .dispatch("saveStaffing", this.form)
            .then((response) => {
              if (response.message == "Success") {
                Swal.fire({
                  title: "",
                  text: "Caregiver has been assigned successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                this.isSubmitting = false;
                this.$emit("staffingSent");
              } else if (response.message == "Failure") {
                Swal.fire({
                  title: "",
                  text: "Error!",
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
              this.isSubmitting = false;
            })
            .catch((ex) => {
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-danger",
              });
              this.isSubmitting = false;
            });
        }
      }
    },
  },
};
</script>

<style scoped>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
</style>
