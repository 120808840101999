<template>
  <div data-app>
    <v-form
      class="form mt-0 mt-lg-10"
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <div
        v-if="this.editclientcontact"
        class="page-title pageheading"
        style="height: 50px"
      >
        <span class="float-right green-btn">
          <a
            v-if="isEditAble"
            class="btn btn-success mr-3"
            @click="editClientinfo"
          >
            <i class="far fa-eye edit_btn"></i><span>Edit</span></a
          >
          <a v-else class="btn btn-success mr-3" @click="submit">
            <i class="far fa-eye edit_btn"></i
            ><span
              >Update
              <span v-if="isSubmitting">
                <v-progress-circular
                  indeterminate
                  color="isPrimary"
                ></v-progress-circular> </span></span
          ></a>
        </span>
      </div>
      <v-col
        v-if="!this.editclientcontact && !this.formPhone.clientContactId"
        class="d-flex mt-4 offset-6 mr-5 justify-content-end"
        cols="12"
        sm="6"
      >
        <v-btn @click="submit">
          submit
          <span v-if="isSubmitting">
            <v-progress-circular
              indeterminate
              color="isPrimary"
            ></v-progress-circular>
          </span> </v-btn
      ></v-col>
      <v-row class="p-2">
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.firstName.$error }"
        >
          <v-text-field
            v-model.trim="$v.form.firstName.$model"
            label="First Name *"
            :disabled="isEditAble"
            maxlength="50"
            ><template #label>
              First Name
              <span class="red"> *</span></template
            ></v-text-field
          >
          <div class="invalid-feedback" v-if="$v.form.firstName.$error">
            <span v-if="$v.form.firstName.$error">First Name is required</span>
          </div>
        </div>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.lastName.$error }"
        >
          <v-text-field
            v-model.trim="$v.form.lastName.$model"
            label="Last Name *"
            :disabled="isEditAble"
            maxlength="50"
            ><template #label>
              Last Name
              <span class="red"> *</span></template
            ></v-text-field
          >
          <div class="invalid-feedback" v-if="$v.form.lastName.$error">
            <span v-if="$v.form.lastName.$error">Last Name is required</span>
          </div>
        </div> 
        <div
          class="form-group col-md-6 mb-form"
         >
          <v-text-field
            v-model.trim="form.email"
            label="Email *"
            maxlength="50"
            :disabled="isEditAble"
            ><template #label>
              Email
               </template
            ></v-text-field
          >
         
        </div>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.relation.$error }"
        >
          <v-text-field
            v-model.trim="$v.form.relation.$model"
            label=" Relation to Client "
            maxlength="50"
            :disabled="isEditAble"
            ><template #label>
              Relation to Client
              <span class="red"> *</span></template
            ></v-text-field
          >
          <div class="invalid-feedback" v-if="$v.form.relation.$error">
            <span v-if="$v.form.relation.$error">Relation is required</span>
          </div>
        </div>
        <v-col cols="12" sm="6">
          <v-textarea
            v-model="form.Comments"
            filled
            :disabled="isEditAble"
            auto-grow
            label="Note"
            maxlength="250"
            rows="3"
            row-height="30"
            shaped
          ></v-textarea>
        </v-col> 

        <v-col class="d-flex" cols="12">
          <v-checkbox
            :disabled="isEditAble"
            v-model="form.isPrimary"
            label="Primary Contact"
          ></v-checkbox>
        </v-col>

        <div class="page-title pageheading" style="height: 50px">
          <span class="float-right green-btn">
            <a v-if="this.editclientcontact && !this.isEditAble" class="btn btn-success mr-3" @click="create">
              <i class="material-icons">add</i> <span>New Phone </span>
            </a>
            <a v-if="!this.editclientcontact"  class="btn btn-success mr-3" @click="create">
              <i class="material-icons">add</i> <span>New  Phone </span>
            </a>
            
          </span>
        </div>
        <div
          v-if="!isfetching"
          class="container mt-10"
          style="background-color: #ffffff"
        >
          <b-table
            class="table table-bordered"
            show-empty
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="0"
            striped
            hover
            select-mode="single"
            selectable
            @row-selected="onRowSelected"
          >
            <template v-slot:head(phoneNumber)>
              <span> Phone Number <small>(xxx) xxx-xxxx</small> </span>
            </template>
            <template #cell(actions)="row">
              <a @click="Edit(row.item)" class="mr-1">
                <i class="far fa-eye edit_btn"></i>
              </a>
              <a
                v-b-modal.confirmContactPhoneModal
                @click="sendInfo(row.item.id)"
                class="mr-1"
              >
                <i class="fas fa-trash-alt del_btn"></i>
              </a>
            </template>
            <template #cell(isPrimary)="row">
              {{ isPrimaryValue(row.item.isPrimary) }}
            </template>
            <template #cell(phoneNumber)="row">
              {{ phoneNumberFormat(row.item.phoneNumber) }}
            </template>
          </b-table>
          <div class="row">
            <div class="col-6">
              <b-pagination
                size="md"
                :total-rows="totalItems"
                v-model="currentPage"
                v-if="perPage != '-1'"
                :per-page="perPage"
              ></b-pagination>
            </div>
            <div class="col-6 text-right">
              <span style="margin-right: 10px"
                ><label for="">Show : </label></span
              >
              <select
                v-model="perPage"
                class="form-select"
                style="border: groove; width: 6%"
                aria-label="Default select example"
                @change="Search()"
              >
                <option selected>50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="-1">All</option>
              </select>

              <span style="margin-left: 10px; margin-right: 20px">
                <label for=""> Entries </label></span
              ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
            </div>
          </div>
        </div>
        <div
          v-else
          class="container mt-10"
          style="background-color: #ffffff; text-align: center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-row>
    </v-form>
    <b-modal
      size="xl"
      id="addCommunication"
      title="Add Phone "
      centered
      no-close-on-backdrop
      hide-footer
    >
      <v-row>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.formPhone.deviceType.$error }"
        >
          <label> Phone Type </label><span class="red"> * </span>
          <b-form-select
            class="form-control"
            v-model.trim="$v.formPhone.deviceType.$model"
            :rules="['Required']"
            required
            :options="deviceType"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.formPhone.deviceType.$error">
            <span v-if="$v.formPhone.deviceType.$error">
              DeviceType is Required
            </span>
          </div>
        </div>
        <v-col cols="12" sm="6">
          <v-checkbox
            v-model="formPhone.isPrimary"
            label="Primary Contact"
          ></v-checkbox>
        </v-col>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.formPhone.phoneNumber.$error }"
        >
          <v-text-field
            v-model.trim="$v.formPhone.phoneNumber.$model"
            label="phoneNumber"
            type="tel"
            @input="enforcePhoneFormat()"
            :max="14"
            maxlength="14"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            ><template #label>
              Phone Number
              <span class="red"> *</span></template
            ></v-text-field
          >
          <div class="invalid-feedback" v-if="$v.formPhone.phoneNumber.$error">
            <span v-if="$v.formPhone.phoneNumber.$error"
              >Phone Number is required</span
            >
          </div>
        </div>
        <v-col cols="12" sm="6">
          <v-textarea
            filled
            v-model="formPhone.note"
            auto-grow
            label="Note"
            maxlength="250"
            rows="3"
            row-height="30"
            shaped
          ></v-textarea>
        </v-col>
      </v-row>
      <span class="green-btn">
        <a class="btn btn-success mr-3" @click="ok()"
          ><span
            >Save phone
            <span v-if="isSubmitting">
              <v-progress-circular
                indeterminate
                color="isPrimary"
              ></v-progress-circular> </span></span
        ></a>
      </span>
      <span class="green-btn">
        <a
          class="btn btn-danger mr-3"
          @click="$bvModal.hide('addCommunication')"
          ><span>Cancel</span></a
        >
      </span>
    </b-modal>
    <b-modal
      centered
      text
      id="confirmContactPhoneModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="remove(contactPhoneId)">
            <span>YES I'M SURE</span>
            <span v-if="isRemoving">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </span>
          </a>
        </span>
        <span class="green-btn">
          <a
            class="btn btn-danger mr-3"
            @click="$bvModal.hide('confirmContactPhoneModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";


export default {
  mixins: [validationMixin],
  validations: {
    form: {
      firstName: { required },
      relation: { required },
      lastName: { required },  
    },
    formPhone: {
      deviceType: { required },
      phoneNumber: { required, minLength: minLength(14) },
    },

    checkbox: {
      checked(val) {
        return val;
      },
    },
  },  
  data() {
    return {
      isEditAble: true,
      isfetching: false,
      isRemoving: false,
      isSubmitting: false,
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      clientContact:'',
      valid: true,
      items: [],
      fields: [
        {
          key: "isPrimary",
          label: "Primary Phone",
        },
        {
          key: "deviceType",
          label: "Phone Type",
        },
        {
          key: "phoneNumber",
          label: "Phone Number",
        },
        {
          key: "note",
          label: "Note",
        },

        {
          key: "actions",
          label: "Action",
        },
      ],
      form: {
        firstName: "",
        lastName: "",
        // address: "",
        // state: "",
        // city: "",
        // zip: "",
        email: "",
        relation: "",
        note: "",
        isPrimary: false,
        sendPortalCredential: false,
        clientId: "",
      },
      formPhone: {
        deviceType: "",
        phoneNumber: "",
        note: "",
        isPrimary: false,
        clientContactId: "",
      },
      Contacts: [],
      Relation: [
        { value: "", text: "Please Select Relation" },
        { value: "Brother", text: "Brother" },
        { value: "Sister", text: "Sister" },
        { value: "Mother", text: "Mother" },
        { value: "Father", text: "Father" },
      ],
      deviceType: [
        { value: "", text: "Please Device Type" },
        { value: "cell", text: "Cell" },
        { value: "home", text: "Home" },
        { value: "work", text: "Work" },
      ],
    };
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
  },
  mounted() {
    if (this.editclientcontact) {
      this.form = this.editclientcontact;
      this.formPhone.clientContactId = this.form.id;
      this.clientContact = this.form.id;
      this.fetchData().catch((error) => {
        console.error(error);
      });
    } else {
      this.isEditAble = false;
    }
  },
  computed: {
    ...mapGetters([
      "editClient",
      "editclientcontact",
      "totalClientContactsPhoneRow",
      "clientContactsPhone",
    ]),
  },
  props: {
    clientIdprop: String,
  },

  methods: {
    phoneNumberFormat(obj) {
      if (obj && obj.length > 0) {
        let x = obj.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

        return !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      } else {
        return null;
      }
    },
    enforcePhoneFormat() {
      let x = this.formPhone.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      this.formPhone.phoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    editClientinfo() {
      this.isEditAble = false;
    },
    Search() {
      this.fetchData();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    sendInfo(item) {
      this.contactPhoneId = item;
    },
    onRowSelected(items) {
      this.Edit(items[0]);
    },
    isPrimaryValue(obj) {
      return obj ? "Yes" : "No";
    },
    async Edit(obj) {
      if (obj != undefined && !this.isEditAble) {
        this.formPhone = obj;
        this.$bvModal.show("addCommunication");
      }
    },
    async fetchData() {
      this.isfetching = true;
      var id = this.formPhone.clientContactId;
      await this.$store
        .dispatch("getClientContactsPhone", {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          id: id,
        })
        .then((response) => {
          if (response.message == "Success") {
            this.isfetching = false;
            this.items = response.data.output;
            this.totalItems = response.data.totalRow;
          }
        })
        .catch((ex) => {
          this.isfetching = false;
          
        });
    },
    remove(obj) {
      this.isRemoving = true;
      this.$store
        .dispatch("removeContactPhone", {
          id: obj,
        })
        .then((response) => {
          this.fetchData();
          this.isRemoving = false;
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "This contact has been deleted successfully",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        })
        .catch((ex) => {
          
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });
      this.$bvModal.hide("confirmContactPhoneModal");
    },
    create() {
      this.formPhone = {
        deviceType: "",
        phoneNumber: "",
        note: "",
        isPrimary: false, 
        clientContactId: this.clientContact, 
      },
      this.$bvModal.show("addCommunication");
    },
    onfocusin() {},
    async submit() {
      if (this.items.length > 0) {
        this.$v.$touch();
        if (this.$refs.form.validate() && !this.$v.form.$invalid) {
          this.isSubmitting = true;
          this.form.clientId = this.editClient.id;
          if (this.form.id) {
            await this.$store
              .dispatch("updateContacts", this.form)
              .then((res) => {
                this.$emit("contactSent");
                this.isSubmitting = false;
                Swal.fire({
                  title: "",
                  text: "This contact has been updated successfully.",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
              })
              .finally(() => {
                this.$store
                  .dispatch("saveContactsPhone", this.items)
                  .then(() => {
                    this.fetchData();
                    this.$emit("staffingSent");
                    this.isSubmitting = false;
                    Swal.fire({
                      title: "",
                      text: "This contact number has been created successfully.",
                      icon: "success",
                      confirmButtonClass: "btn btn-secondary",
                    });
                  })
                  .catch(() => {
                    this.isSubmitting = false;
                  });
              })
              .catch(() => {
                this.isSubmitting = false;
              });
          } else {
            await this.$store
              .dispatch("saveContacts", this.form)
              .then((response) => {
                if (response != undefined) {
                  this.formPhone.clientContactId = response.data;
                  this.clientContact= response.data;
                  this.isSubmitting = false;
                  this.isEditAble = true;
                  this.$emit("contactSent");
                  Swal.fire({
                    title: "",
                    text: "This contact has been created successfully.",
                    icon: "success",
                    confirmButtonClass: "btn btn-secondary",
                  }).finally(() => {
                    this.$store
                      .dispatch("saveContactsPhone", this.items)
                      .then(() => {
                        this.fetchData();
                        this.$emit("staffingSent");
                        this.isSubmitting = false;
                        this.isEditAble = true;
                      })
                      .catch(() => {
                        this.isSubmitting = false;
                      });
                  });
                } else {
                  this.isSubmitting = false;
                }
              })
              .catch(() => {
                this.isSubmitting = false;
              });
          }
        }
      } else {
        Swal.fire({
          title: "",
          text: "Add a phone number to save the contact",
          icon: "error",
          confirmButtonClass: "btn btn-error",
        });
      }
    },
    ok() {
      this.$v.formPhone.$touch();
      if (!this.$v.formPhone.$invalid) {
        if (this.formPhone.id) {
          // this.$store
          //   .dispatch("updateContactsPhone", this.formPhone)
          //   .then(() => {
          //     this.fetchData();
          //     this.isSubmitting = false;
          //     Swal.fire({
          //       title: "",
          //       text: "This contact number has been updated successfully.",
          //       icon: "success",
          //       confirmButtonClass: "btn btn-secondary",
          //     });
          //   })
          //   .catch(() => {
          //     this.isSubmitting = false;
          //   });
        } else {
          this.items.push(this.formPhone);
          // this.$store
          //   .dispatch("saveContactsPhone", this.formPhone)
          //   .then(() => {
          //     this.fetchData();
          //     this.$emit("staffingSent");
          //     this.isSubmitting = false;
          //     Swal.fire({
          //       title: "",
          //       text: "This contact number has been created successfully.",
          //       icon: "success",
          //       confirmButtonClass: "btn btn-secondary",
          //     });
          //   })
          //   .catch(() => {
          //     this.isSubmitting = false;
          //   });
        }

        this.$bvModal.hide("addCommunication");
      }
    },
  },
};
</script>

<style>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}
.table {
  box-shadow: 0 0 5px #ccc;
}
.row {
  margin: 0px !important;
}

.requiredfield {
  color: rgba(252, 9, 9, 0.7);
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
</style>
