<template>
  <div data-app>
    <v-form
      class="form mt-0 mt-lg-10"
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <div
        v-if="this.editcommunications"
        class="page-title pageheading"
        style="height: 50px"
      >
        <span class="float-right green-btn">
          <a
            v-if="isEditAble"
            class="btn btn-success mr-3"
            @click="editClientinfo"
          >
            <i class="far fa-eye edit_btn"></i><span>Edit</span></a
          >
          <a v-else class="btn btn-success mr-3" @click="submit">
            <i class="far fa-eye edit_btn"></i
            ><span
              >Update
              <span v-if="isSubmitting">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular> </span></span
          ></a>
        </span>
      </div>
      <v-row class="p-2">
        <!-- <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.date.$error }"
        >
          <label> Date </label><span class="red"> *</span>
          <date-picker
            v-model.trim="$v.form.date.$model"
            ref="dp1"
            :disabled="isEditAble"
            @focusin.native="onfocusin"
            placeholder="Choose Date"
            :config="datePickerOptions"
          ></date-picker>
          <div class="invalid-feedback" v-if="$v.form.date.$error">
            <span v-if="$v.form.date.$error">Date is required</span>
          </div>
        </div> -->
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.category.$error }"
        >
          <label> Category </label><span class="red"> *</span>
          <b-form-select
            class="form-control"
            v-model.trim="$v.form.category.$model"
            :rules="['Required']"
            :disabled="isEditAble"
            required
            :options="category"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.category.$error">
            <span v-if="$v.form.category.$error">Category is required</span>
          </div>
        </div>

        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.subject.$error }"
        >
          <v-text-field
            v-model="form.subject"
            :disabled="isEditAble"
            label="Subject *"
            maxlength="50"
            ><template #label>
              Subject
              <span class="red"> *</span></template
            ></v-text-field
          >
          <div class="invalid-feedback" v-if="$v.form.subject.$error">
            <span v-if="$v.form.subject.$error">Subject is required</span>
          </div>
        </div>

        <div class="form-group col-md-12 mb-form">
          <label> Associated Party</label>
          <!-- <multiselect
            v-model="form.AssociatedParties"
            placeholder="Associated Party"
            label="name"
            :disabled="isEditAble"
            track-by="id"
            :options="AssocationParties"
            :multiple="true"
          ></multiselect>
        -->

          <multi-list-select
            :list="AssocationParties"
            option-value="id"
            option-text="name"
            :isDisabled="isEditAble"
            :selected-items="AssociatedPartiesList"
            placeholder="select item"
            @select="onSelectlist"
          >
          </multi-list-select>
        </div>
        <v-col cols="12" sm="12">
          <v-textarea
            filled
            v-model="form.comment"
            auto-grow
            :disabled="isEditAble"
            label="Comments"
            maxlength="250"
            rows="4"
            row-height="30"
            shaped
          ></v-textarea>
        </v-col>
      </v-row>
      <v-col
        v-if="!this.editcommunications"
        class="d-flex mt-4"
        cols="12"
        sm="6"
      >
        <v-btn @click="submit">
          submit
          <span v-if="isSubmitting">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span> </v-btn
      ></v-col>
    </v-form>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

import { MultiListSelect } from "vue-search-select";
var today = new Date();

export default {
  components: {
    Multiselect,
    MultiListSelect,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      subject: { required },
      category: { required },
    },
    AssociatedPartiesList: { required },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  mounted() {
    if (this.editcommunications) {
      this.form = this.editcommunications;
      this.form.date = this.dobformat(this.form.date);
      this.form.AssociatedParties = [];
      this.form.taskAssociatedParties.forEach((e) => {
        if (e.clientName.trim()) {
          this.form.AssociatedParties.push({
            id: e.clientId,
            name: e.clientName.trim(),
            type: "Client",
          });
        } else if (e.caregiverName.trim()) {
          this.form.AssociatedParties.push({
            id: e.caregiverId,
            name: e.caregiverName.trim(),
            type: "Caregiver",
          });
        }
      });
      this.AssociatedPartiesList = this.form.AssociatedParties;
    } else {
      this.isEditAble = false;
    }
    this.form.createdBy = this.GetAuthenticatedUser.userName;
  },
  computed: {
    ...mapGetters([
      "editClient",
      "editcommunications",
      "AssocationParties",
      "editEmployee",
      "GetAuthenticatedUser",
    ]),
  },
  props: {
    clientIdprop: String,
  },
  data() {
    return {
      AssociatedPartiesList: [],
      AssociatedPartyError: false,
      isEditAble: true,
      datePickerOptions: {
        format: "MM/DD/YYYY",
        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
      },

      isSubmitting: false,
      valid: true,
      form: {
        date: null,
        subject: "",
        category: "",
        comment: "",
        AssociatedParties: "",
        createdBy: "",
        taskAssociatedParties: "",
      },

      category: [
        { value: "", text: "Please Select category" },
        { value: "General", text: "General Communication" },
        { value: "Incident", text: "Incident" },
        { value: "Disciplinary", text: "Disciplinary" },
        { value: "Troubleshoot", text: "Troubleshoot" },
        { value: "Billing", text: "Billing/Authorizations" },
        { value: "Payroll", text: "Payroll" },
        { value: "Quality", text: "Quality Assurance" },
      ],
    };
  },
  methods: {
    onfocusin() {},
    dobformat(obj) {
      if (obj != null) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    editClientinfo() {
      this.isEditAble = false;
    },
    onSelectlist(items, lastSelectItem) {
      this.AssociatedPartiesList = items;
    },
    submit() {
      this.$v.$touch();

      if (this.$refs.form.validate() && !this.$v.form.$invalid) {
        this.isSubmitting = true;
        if (this.editClient) {
          this.form.clientId = this.editClient.id;
        } else {
          this.form.caregiverId = this.editEmployee.id;
        }
        this.form.taskAssociatedParties = [];
        this.form.date = new Date(this.form.date);
        this.AssociatedPartiesList.forEach((e) => {
          if (e.type == "Client") {
            this.form.taskAssociatedParties.push({ clientId: e.id });
          }
          //  else if (e.type == "Caregiver") {
          //   this.form.taskAssociatedParties.push({ caregiverId: e.id });
          // }
          else {
            this.form.taskAssociatedParties.push({ caregiverId: e.id });
          }
        });

        if (this.form.id) {
          this.$store
            .dispatch("updateCommunication", this.form)
            .then((response) => {
              if (response.message == "Success") {
                Swal.fire({
                  title: "",
                  text: "The communication has been updated successfully",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                this.isSubmitting = false;
                this.$emit("communicationSent");
              } else if (response.message == "Failure") {
                Swal.fire({
                  title: "",
                  text: "Error!",
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
              this.isSubmitting = false;
            })
            .catch((ex) => {
              this.isSubmitting = false;
            });
        } else {
          this.$store
            .dispatch("saveCommunication", this.form)
            .then((response) => {
              if (response.message == "Success") {
                Swal.fire({
                  title: "",
                  text: "The communication has been added successfully",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                this.isSubmitting = false;
                this.$emit("communicationSent");
              } else if (response.message == "Failure") {
                Swal.fire({
                  title: "",
                  text: "Error!",
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
              this.isSubmitting = false;
            })
            .catch((ex) => {
              this.isSubmitting = false;
            });
        }
      }
    },
  },
};
</script>

<style>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.red {
  color: red;
}
</style>
