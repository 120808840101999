<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <span class="ml-3">Communication </span>
      </h3>

      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="create">
          <i class="material-icons">add</i> <span>Communication</span></a
        >
      </span>
    </div>
    <div>
      <v-row>
        <v-col class="d-flex" cols="12" sm="4">
          <v-row>
            <label class="mt-2"> From Date :</label>
          </v-row>
          <v-row>
            <date-picker
              v-model="fromDate"
              ref="dp1"
              @focusin.native="onfocusin"
              placeholder="Choose From Date"
              @dp-change="dataStartChange"
              :config="datePickerOptions"
            ></date-picker>
          </v-row>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-row>
            <label class="mt-2"> To Date :</label>
          </v-row>
          <v-row>
            <date-picker
              v-model="toDate"
              ref="dp1"
              class="dpdataToDate"
              @focusin.native="onfocusin"
              placeholder="Choose To Date"
              :config="datePickerOptionsEnd"
            ></date-picker>
          </v-row>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-btn @click="Search"> Search </v-btn></v-col
        >
      </v-row>
    </div>
    <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="totalItems"
        striped
        hover
        select-mode="single"
        selectable
        @row-selected="onRowSelected"
      >
        <template #cell(createdDate)="row">
          {{ dobformat(row.item.createdDate) }}
        </template>
        <template #cell(comment)="row">
          {{ commentsValue(row.item.comment) }}
        </template>
        <template #cell(subject)="row">
          {{ commentsValue(row.item.subject) }}
        </template>
        <template #cell(actions)="row">
          <a @click="Edit(row.item)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </a>

          <a
            v-b-modal.confirmClientCommunicationModal
            @click="sendInfo(row.item.id)"
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </a>
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            v-if="perPage != '-1'"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <b-modal
      size="xl"
      id="communication"
      title="Add New Communication "
      centered
      no-close-on-backdrop
      hide-footer
    >
      <Communication @communicationSent="closeModal"></Communication>
    </b-modal>
    <b-modal
      centered
      text
      id="confirmClientCommunicationModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="remove(communicationid)"
            ><span>YES I'M SURE</span>
            <span v-if="isRemoving">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular> </span
          ></a>
        </span>
        <span class="green-btn">
          <a
            class="btn btn-danger mr-3"
            @click="$bvModal.hide('confirmClientCommunicationModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import Communication from "./addCommunication.vue";
import { validationMixin } from "vuelidate";
var today = new Date();
export default {
  components: {
    Communication,
  },
  mixins: [validationMixin],
  validations: {},
  data() {
    return {
      datePickerOptions: {
        format: "MM/DD/YYYY",
        maxDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          23,
          59,
          59
        ),
        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
      },
      datePickerOptionsEnd: {
        format: "MM/DD/YYYY",
        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
      },
      isfetching: false,
      isRemoving: false,
      isSending: false,
      communicationid: "",
      items: [],
      fields: [
        {
          key: "category",
          label: "Category",
        },
        {
          key: "subject",
          label: "Subject",
        },
        {
          key: "clientName",
          label: "Created by",
        },
        {
          key: "createdDate",
          label: "Created on",
        },
        {
          key: "comment",
          label: "Comments",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      fromDate: "",
      toDate: "",
      currentPage: 1,
      perPage: 50,
      totalItems: 10,
      search: "",
      id: "",
    };
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
    totalRow: {
      handler: function () {},
    },
  },
  mounted() {
    this.fetchData().catch((error) => {
      console.error(error);
    });
  },
  computed: {
    ...mapGetters([
      "Communications",
      "totalCommunicationsRow",
      "editClient",
      "editEmployee",
    ]),
  },
  methods: {
    dataStartChange: (e) => {
      document
        .querySelectorAll(".dpdataToDate")[0]
        .__vue__.dp.minDate(e.date._d);

      var endDate = new Date(
        document.querySelectorAll(".dpdataToDate")[0].value
      );
      var startDate = new Date(e.date._d);

      if (endDate <= startDate) {
        Swal.fire({
          title: "",
          text: "The End Date is less than Start Date!",
          icon: "error",
          confirmButtonClass: "btn btn-danger",
        });
        document.querySelectorAll(".dpdataToDate")[0].value = null;
      }
    },
    sendInfo(item) {
      this.communicationid = item;
    },
    dobformat(obj) {
      if (obj != null) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    commentsValue(obj) {
      if (obj.length > 20) {
        return obj.substring(0, 20).concat("...");
      } else {
        return obj;
      }
    },
    create() {
      this.$store.dispatch("editcommunications", null);
      this.$bvModal.show("communication");
    },
    async fetchData() {
      if (this.editClient || this.editEmployee) {
        this.isfetching = true;
        if (this.editClient) {
          var id = this.editClient.id;
        } else {
          var id = this.editEmployee.id;
        }
        this.$store.dispatch("getAssocationParties", id);
        await this.$store
          .dispatch("getCommunications", {
            pageNo: this.currentPage,
            pageSize: this.perPage,
            id: id,
          })
          .then((response) => {
            if (response.message == "Success") {
              this.isfetching = false;
              this.items = this.Communications;
              this.totalItems = this.totalCommunicationsRow;
            }
          })
          .catch((ex) => {
            this.isfetching = false;
          });
      } else {
        this.$store.dispatch("getAssocationParties");
      }
    },
    onRowSelected(items) {
      this.Edit(items[0]);
    },
    async Edit(obj) {
      if (obj != undefined) {
        await this.$store.dispatch("editcommunications", obj.id).then(() => {
          this.$bvModal.show("communication");
        });
      }
    },
    closeModal() {
      this.fetchData();
      this.$bvModal.hide("communication");
    },
    onfocusin() {},
    async remove(obj) {
      this.isRemoving = true;
      await this.$store
        .dispatch("removeCommunications", {
          id: obj,
        })
        .then((response) => {
          this.isRemoving = false;
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "The Communication has been Deleted Successfully!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
          this.fetchData();
        })
        .catch((ex) => {
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });
      this.$bvModal.hide("confirmClientCommunicationModal");
    },
    ok() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isSending = true;
        if (this.id) {
          this.$store
            .dispatch("editPayers", {
              id: this.id,
              companyName: this.companyName,
            })
            .then((response) => {
              this.isSending = false;
              this.items = this.payers;
              Swal.fire({
                title: "",
                text: "The Payer has been Updated Successfully!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              this.$bvModal.hide("communication");
            })
            .catch((ex) => {
              this.isSending = false;

              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        } else {
          this.$store
            .dispatch("savePayers", {
              companyName: this.companyName,
            })
            .then((response) => {
              this.items = this.payers;
              this.isSending = false;
              if (response.message == "Success") {
                Swal.fire({
                  title: "",
                  text: "The Payer has been Created Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
              }
              this.$bvModal.hide("communication");
            })
            .catch((ex) => {
              this.isSending = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        }
      }
    },
    async Search() {
      if (this.editClient) {
        var id = this.editClient.id;
      } else {
        var id = this.editEmployee.id;
      }

      await this.$store
        .dispatch("getCommunications", {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          id: id,
          fromDate: this.fromDate,
          toDate: this.toDate,
        })
        .then((response) => {
          if (response.message == "Success") {
            this.isfetching = false;
            this.items = this.Communications;
            this.totalItems = this.totalCommunicationsRow;
          }
        })
        .catch((ex) => {
          this.isfetching = false;
        });
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
  },
};
</script>
<style scoped>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}
.table {
  box-shadow: 0 0 5px #ccc;
}
.row {
  margin: 0px !important;
}
.requiredfield {
  color: rgba(252, 9, 9, 0.7);
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}

.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
</style>
