<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <span class="ml-3">Contacts</span>
      </h3>

      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="create">
          <i class="material-icons">add</i> <span>Contact</span></a
        >
      </span>
    </div>
    <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        striped
        hover
        select-mode="single"
        selectable
        @row-selected="onRowSelected"
      >
        <template v-slot:head(phone)>
          <span> Phone <small>(xxx) xxx-xxxx</small> </span>
        </template>
        <template #cell(actions)="row">
          <a @click="Edit(row.item)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </a>
          <a
            v-b-modal.confirmContactModal
            @click="sendInfo(row.item.id)"
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </a>
          <!-- <b-button variant="primary">Edit</b-button>
          <b-button variant="danger">Delete</b-button> -->
        </template>
        <template #cell(isPrimary)="row">
          {{ isPrimaryValue(row.item.isPrimary) }}
        </template>
        <template #cell(phone)="row">
          {{ phoneNumberFormat(row.item.clientContactPhoneNumbers) }}
        </template>

        <template #cell(firstName)="row">
          {{ nameValue(row.item.firstName) }}
        </template>
        <template #cell(email)="row">
          {{ nameValue(row.item.email) }}
        </template>
        <template #cell(lastName)="row">
          {{ nameValue(row.item.lastName) }}
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
            v-if="perPage != '-1'"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <b-modal
      size="xl"
      id="addClientContact"
      title="Add Contact    "
      centered
      no-close-on-backdrop
      hide-footer
    >
      <contact @contactSent="closeModal"> </contact>>
    </b-modal>
    <b-modal
      centered
      text
      id="confirmContactModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="remove(clientContactid)">
            <span>YES I'M SURE</span>
            <span v-if="isRemoving">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </span>
          </a>
        </span>
        <span class="green-btn">
          <a
            class="btn btn-danger mr-3"
            @click="$bvModal.hide('confirmContactModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import contact from "./contact.vue";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  components: {
    contact,
  },
  mixins: [validationMixin],
  validations: {},
  data() {
    return {
      isfetching: false,
      isRemoving: false,
      isSending: false,
      items: [],
      fields: [
        {
          key: "isPrimary",
          label: "Primary",
        },
        {
          key: "firstName",
          label: "First Name",
        },
        {
          key: "lastName",
          label: "Last Name",
        },
        {
          key: "relation",
          label: "Relation to Client",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "phone",
          label: "Phone",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 10,
      search: "",
      companyName: "",
      phoneNumber: "",
      location: "",
      id: "",
      type: "",
      caseworkers: [],
      rules: {
        required: (value) => !!value || "Company Name is Required.",
        counter: (value) => value.length <= 50 || "Max 50 characters",
      },
    };
  },

  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
    totalRow: {
      handler: function () {},
    },
  },
  mounted() {
    this.fetchData().catch((error) => {
      console.error(error);
    });
  },
  computed: {
    ...mapGetters(["totalClientContactsRow", "clientContacts", "editClient"]),
  },
  methods: {
    phoneNumberFormat(obj) {
      if (obj && obj.length > 0) {
        let x = obj[0].phoneNumber
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

        return !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      } else {
        return null;
      }
    },
    nameValue(obj) {
      if (obj && obj.length > 20) {
        return obj.substring(0, 20).concat("...");
      } else {
        return obj;
      }
    },
    sendInfo(item) {
      this.clientContactid = item;
    },
    async create() {
      await this.$store.dispatch("editclientcontact", null);
      this.$bvModal.show("addClientContact");
    },
    closeModal() {
      this.fetchData();
    },
    isPrimaryValue(obj) {
      return obj ? "Yes" : "No";
    },
    async fetchData() {
      if (this.editClient) {
        this.isfetching = true;
        var id = this.editClient.id;
        
        await this.$store
          .dispatch("getClientContacts", {
            pageNo: this.currentPage,
            pageSize: this.perPage,
            id: id,
          })
          .then((response) => {
            if (response.message == "Success") {
              this.isfetching = false;
              this.items = this.clientContacts;
              this.totalItems = this.totalClientContactsRow;
            }
          })
          .catch((ex) => {
            this.isfetching = false;
            
          });
      }
    },
    onRowSelected(items) {
      this.Edit(items[0]);
    },
    async Edit(obj) {
      if (obj != undefined) {
        await this.$store.dispatch("editclientcontact", obj.id).then(() => {
          this.$bvModal.show("addClientContact");
        });
      }
    },
    remove(obj) {
      this.isRemoving = true;
      this.$store
        .dispatch("removeClientContacts", {
          id: obj,
        })
        .then((response) => {
          this.isRemoving = false;
          this.fetchData();
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "This contact has been deleted successfully",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        })
        .catch((ex) => {
          
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });
      this.$bvModal.hide("confirmContactModal");
    },
    ok() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isSending = true;
      }
    },
    Search() {
      this.search = this.search == null ? "" : this.search;
      this.fetchData();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
  },
};
</script>
<style scoped>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}
.table {
  box-shadow: 0 0 5px #ccc;
}
.row {
  margin: 0px !important;
}
.requiredfield {
  color: rgba(252, 9, 9, 0.7);
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
</style>
