<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">AUTHORIZATIONS</span>
      </h3>

      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="authorize">
          <i class="material-icons">add</i> <span>New Authorizations</span></a>
      </span>
    </div>

    <div class="container mt-10" style="background-color: #ffffff">
      <b-table class="table table-bordered" show-empty :items="items" :fields="fields" striped hover
        select-mode="single" selectable @row-selected="onRowSelected">
        <template #cell(startDate)="row">
          {{ dobformat(row.item.startDate) }}
        </template>
        <template #cell(endDate)="row">
          {{ dobformat(row.item.endDate) }}
        </template>
        <template #cell(modifiedBy)="row">
          {{ row.item.modifiedBy }}
        </template>
        <template #cell(dateModified)="row">
          {{ dobformat(row.item.modifiedDate) }}
        </template>

        <template #cell(termDate)="row">
          {{ dobformat(row.item.termDate) }}
        </template>
        <template #cell(usageNotes)="row">
          {{ nameValue(row.item.usageNotes) }}
        </template>

        <template #cell(vendorCode)="row">
          {{ vendorCodeName(row.item) }}
        </template>
        <template #cell(ServiceCode)="row">
          {{ subCodeName(row.item) }}
        </template>
        <template #cell(caseWorkerId)="row">
          {{ caseworkerName(row.item) }}
        </template>
        <template #cell(actions)="row">
          <a @click="Edit(row.item)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </a>
          <a v-b-modal.confirmAuthModal @click="sendInfo(row.item.id)" class="mr-1">
            <i class="fas fa-trash-alt del_btn"></i>
          </a>
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination v-if="perPage != '-1'" size="md" :total-rows="totalItems" v-model="currentPage"
            :per-page="perPage"></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select v-model="perPage" class="form-select" style="border: groove; width: 6%"
            aria-label="Default select example" @change="Search()">
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div>
    <b-modal size="xl" id="authorize" hide-footer centered no-close-on-backdrop>
      <template slot="modal-title">Add New Authorizations</template>
      <createwizard @authorizeSent="closeModal"></createwizard>
    </b-modal>
    <b-modal centered text id="confirmAuthModal" title="Are you sure you want to Delete?" hide-footer>
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="remove(authid)"><span>YES I'M SURE
              <span v-if="isRemoving">
                <v-progress-circular indeterminate color="primary"></v-progress-circular> </span></span></a>
        </span>
        <span class="green-btn">
          <a class="btn btn-danger mr-3" @click="$bvModal.hide('confirmAuthModal')"><span>Cancel</span></a>
        </span>
      </div>
    </b-modal>
  </div>
</template>
<script>
import createwizard from "./authorize.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  components: {
    createwizard,
  },
  props: {
    clientIdprop: String,
  },
  data() {
    return {
      isfetching: false,
      isRemoving: false,
      search: "",
      authid: "",
      currentPage: 1,
      totalItems: 0,
      perPage: 50,
      showChangePass: true,
      clientid: "",
      items: [],
      fields: [
        {
          key: "vendorCode",
          label: "Vendor",
        },
        {
          key: "ServiceCode",
          label: "Service",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "authorizationNumber",
          label: "Auth #",
        },
        {
          key: "startDate",
          label: "Start Date",
        },
        {
          key: "endDate",
          label: "End Date",
        },

        {
          key: "hours",
          label: "Hours",
        },
        {
          key: "termDate",
          label: "Term Date",
        },
        {
          key: "usageNotes",
          label: "Usage Notes ",
        },
        {
          key: "modifiedBy",
          label: "Modified By",
        },
        {
          key: "dateModified",
          label: "Date Modified",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
    };
  },
  mounted() {
    this.fetchData().catch((error) => { });
    this.$store.dispatch("getCaseWorkerListbyPayerId", {
      payerId: this.editClient.payersId,
    });
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => { });
      },
    },
  },
  computed: {
    ...mapGetters(["authorization", "totalAuthorizeRow", "editClient"]),
  },
  methods: {
    Search() {
      this.fetchData();
    },
    nameValue(obj) {
      if (obj && obj.length > 20) {
        return obj.substring(0, 20).concat("...");
      } else {
        return obj;
      }
    },
    dobformat(obj) {
      if (obj != null) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    onRowSelected(items) {
      this.Edit(items[0]);
    },
    Edit(obj) {
      if (obj != undefined) {
        this.$store.dispatch("editauthorize", obj);
        this.$bvModal.show("authorize");
      }
    },
    closeModal() {
      this.$bvModal.hide("authorize");
      this.fetchData();
    },
    vendorCodeName(obj) {
      if (obj && obj.vendor && obj.vendor.vendorCode) {

        return obj.vendor.vendorCode;
      }
      return null
    },
    subCodeName(obj) {
      if (obj && obj.subCodes && obj.subCodes.subCode) {

return obj.subCodes.subCode;
}
return null 
    },
    caseworkerName(obj) {
      if (obj && obj.caseWorker) {
      return obj.caseWorker.firstName + " " + obj.caseWorker.lastName;}
    },
    async fetchData() {
      if (this.editClient) {
        var id = this.editClient.id;
        await this.$store
          .dispatch("getAuthorize", {
            pageNo: this.currentPage,
            pageSize: this.perPage,
            clientId: id,
          })
          .then((response) => {
            if (response.message == "Success") {
              this.items = this.authorization;
              this.totalItems = this.totalAuthorizeRow;
            }
          })
          .catch((ex) => { });
      }
    },
    sendInfo(item) {
      this.authid = item;
    },
    authorize() {
      this.$store.dispatch("editauthorize", null);
      this.$bvModal.show("authorize");
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    remove(obj) {
      this.isRemoving = true;
      this.$store
        .dispatch("removeAuthClient", {
          id: obj,
        })
        .then((response) => {
          this.$bvModal.hide("confirmAuthModal");
          this.isRemoving = false;
          if (response.status) {
            Swal.fire({
              title: "",
              text: "Success",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          } else {
            Swal.fire({
              title: "",
              text: response.message,
              icon: "error",
              confirmButtonClass: "btn btn-danger",
            });
          }
          this.fetchData();
        })
        .catch((ex) => {
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}

.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}

.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}

.edit_btn:hover {
  color: #fb8c00 !important;
}

.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}

.del_btn:hover {
  color: #bf360c !important;
}
</style>
