<template>
  <div class="card card-custom card-transparent">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div class="row" style="background-color: #ecf0f3">
        <div class="col-md-2 client_profile_thumb pt-7 pl-20">
          <v-avatar
            v-if="url != null"
            style="
              width: 135px;
              object-fit: contain;
              min-height: 140px;
              height: auto;
              overflow: hidden;
              position: relative;
            "
          >
            <img
              :src="url"
              style="
                position: absolute;
                height: 100%;
                width: 100%;
                object-fit: cover;
              "
            />
          </v-avatar>
          <v-avatar v-else size="128">
            <img src="../../public/media/users/2.png" />
          </v-avatar>
          <v-file-input
            v-if="clientId"
            style="margin-top: -30px !important; margin-left: 90px"
            @change="Preview_image"
            v-model="image"
            accept="image/png, image/jpeg, image/bmp"
            prepend-icon="mdi-camera"
            hide-input
          ></v-file-input>
          <v-avatar v-if="url != null" style="margin-top: -46px !important">
            <button @click="Delete()" class="mr-1">
              <i style="color: black" class="fas fa-trash-alt del_btn"></i>
            </button>
          </v-avatar>

          <!-- <figure class="upload-img">

            <img
              id="imgClientProfile"
              src="../../public/media/users/images.png"
            />
          </figure> -->
        </div>
        <div class="col-md-10 pl-15 pt-15">
          <div class="row">
            <h3>
              <span v-if="form.lastName" id="lblMFname"
                >{{ form.lastName }},
              </span>
              <span v-if="form.firstName" id="lblMLastName"
                >{{ form.firstName }}
              </span>
            </h3>
            <span
              v-if="form.uci"
              id="lblMAge"
              class="pl-2 pt-1"
              style="position: relative"
            >
              UCI : {{ form.uci }}</span
            >
          </div>
          <div>
            <span v-if="payerName">
              <a id="linkbtnPayer">{{ payerName.companyName }}</a>
            </span>
            <br />
            <span>
              <a
                id="linkbtnAddress"
                class="font-blue link"
                target="_blank"
                :href="
                  'https://maps.google.com/?q=' +
                  parseFloat(form.latitude) +
                  ',' +
                  parseFloat(form.longitude)
                "
              >
                {{ form.address }} {{ form.otherAddress }}
                <!-- , {  { form.city }} , {{ form.state }} ,
                {{ form.zip }} -->
              </a>
            </span>
            <span>
              <div v-if="form.joinDate" class="font-blue">
                Client Since: {{ foramtDateHeading(form.joinDate) }}
              </div>
            </span>
            <span>
              <div class="font-blue mt-2">
                <a
                  id="status"
                  class="statusColor"
                  v-bind:style="{
                    color: statusColor,
                    'background-color': statusBackGroundColor,
                    padding: '5px',
                  }"
                  >{{ form.status }} {{ foramtDateHeading(TerminationDate) }}</a
                >
              </div>
            </span>
          </div>
        </div>
      </div>
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <div
          class="wizard-nav border-bottom mb-1 mb-lg-5 sticky-top"
          style="background-color: white; z-index: 50 !important"
        >
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <h3 class="wizard-title">INFORMATION</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">INTAKE</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">AUTHORIZATIONS</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">STAFFING</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">TASK/COMMUNICATIONS</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div
              class="wizard-step"
              data-wizard-type="step"
              v-if="
                GetAuthenticatedUser.userType == 'Admin Level 2' ||
                GetAuthenticatedUser.userType == 'JFS' ||
                GetAuthenticatedUser.userType == 'Super Admin'
              "
            >
              <div class="wizard-label">
                <h3 class="wizard-title">CONTACTS</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <!-- <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">HISTORY</h3>
                <div class="wizard-bar"></div>
              </div>
            </div> -->
          </div>
        </div>

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center">
              <div class="col-xl-12 col-sm-12 col-md-12 col-xxl-7">
                <form class="form" id="kt_form">
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div
                      v-if="formId"
                      class="page-title"
                      style="padding-bottom: 50px"
                    >
                      <span class="float-right green-btn">
                        <a
                          v-if="!isEditAble"
                          class="btn btn-success mr-3"
                          @click="editClientinfo"
                        >
                          <i class="far fa-eye edit_btn"></i
                          ><span>Edit</span></a
                        >
                        <a
                          v-else
                          class="btn btn-success mr-3"
                          @click="submitClient"
                        >
                          <i class="far fa-eye edit_btn"></i
                          ><span
                            >Update
                            <span v-if="isUpdating">
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular> </span></span
                        ></a>
                      </span>
                    </div>
                    <div
                      v-if="!isClientexist"
                      class="page-title"
                      style="padding-bottom: 80px"
                    >
                      <span class="float-right green-btn">
                        <v-col cols="12" sm="12" data-app>
                          <b-button
                            v-if="!isClientexist"
                            size="lg"
                            variant="success"
                            class="ml-5"
                            @click="submitClient()"
                          >
                            Save Client Info
                            <span v-if="isSaving">
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              >
                              </v-progress-circular>
                            </span>
                          </b-button>
                        </v-col>
                      </span>
                    </div>
                    <!-- firstName -->
                    <v-row class="p-2" align="center">
                      <!-- payer -->
                      <div
                        class="col-md-6 mb-form"
                        :class="{ invalid: $v.form.payersId.$error }"
                      >
                        <!-- <v-select
                          v-if="!isEditAble"
                          disabled
                          v-model.trim="$v.form.payersId.$model"
                          :items="PayersList"
                          item-text="companyName"
                          item-value="id"
                          label="Payer"
                          :menu-props="{ maxHeight: '400' }"
                          persistent-hint
                        ></v-select>
                        <v-autocomplete
                          v-else
                          v-model="form.payersId"
                          :items="PayersList"
                          item-text="companyName"
                          label="Payer"
                          item-value="id"
                          :menu-props="{ maxHeight: '200' }"
                          required
                          ><template #label>
                            Payer
                            <span class="red">*</span></template
                          ></v-autocomplete
                        > -->
                        <label> Payer </label><span class="red"> *</span>
                        <b-form-select
                          class="form-control"
                          value-field="id"
                          text-field="companyName"
                          v-model="form.payersId"
                          :disabled="!isEditAble"
                          :rules="['Required']"
                          required
                          :options="payersList"
                        ></b-form-select>

                        <div
                          class="invalid-feedback"
                          v-if="$v.form.payersId.$error"
                        >
                          <span v-if="$v.form.payersId.$error"
                            >Payers is required</span
                          >
                        </div>
                      </div>
                      <!-- status -->
                      <div
                        class="col-md-6 mb-form"
                        :class="{ invalid: $v.form.status.$error }"
                      >
                        <label> Status </label><span class="red"> *</span>
                        <!-- <label> Status </label> <span class="red">*</span> -->
                        <b-form-select
                          class="form-control"
                          v-model.trim="$v.form.status.$model"
                          :disabled="!isEditAble"
                          :rules="[(v) => !!v || 'Item is required']"
                          :options="statusdrpdown"
                          @change="getStatus"
                        ></b-form-select>

                        <!-- <v-select
                          v-if="!isEditAble"
                          disabled
                          v-model.trim="$v.form.status.$model"
                          :items="statusdrpdown"
                          label="Status"
                          :menu-props="{ maxHeight: '400' }"
                          persistent-hint
                        ></v-select>

                         
                        <v-autocomplete
                          v-else
                          v-model.trim="$v.form.status.$model"
                          :items="statusdrpdown"
                          :menu-props="{ maxHeight: '200' }"
                          required
                          ><template #label>
                            Status
                            <span class="red">*</span></template
                          ></v-autocomplete
                        > -->

                        <div
                          class="invalid-feedback"
                          v-if="$v.form.status.$error"
                        >
                          <span v-if="$v.form.status.$error"
                            >Status is required</span
                          >
                        </div>
                      </div>
                      <!-- firstname -->
                      <div
                        class="col-md-6 mb-form"
                        :class="{ invalid: $v.form.firstName.$error }"
                      >
                        <v-text-field
                          v-model.trim="$v.form.firstName.$model"
                          v-if="isEditAble"
                          required
                          maxlength="50"
                        >
                          <template #label>
                            First Name
                            <span class="red">*</span>
                          </template>
                        </v-text-field>
                        <v-text-field
                          disabled
                          v-else
                          v-model.trim="$v.form.firstName.$model"
                          maxlength="50"
                        >
                          <template #label>
                            First Name
                            <span class="red">*</span>
                          </template>
                        </v-text-field>

                        <div
                          class="invalid-feedback"
                          v-if="$v.form.firstName.$error"
                        >
                          <span v-if="$v.form.firstName.$error"
                            >First Name is required</span
                          >
                        </div>
                      </div>
                      <!-- last name -->
                      <div
                        class="col-md-6 mb-form"
                        :class="{ invalid: $v.form.lastName.$error }"
                      >
                        <v-text-field
                          v-if="isEditAble"
                          v-model.trim="$v.form.lastName.$model"
                          maxlength="50"
                        >
                          <template #label>
                            Last Name
                            <span class="red">*</span>
                          </template>
                        </v-text-field>
                        <v-text-field
                          v-else
                          v-model.trim="$v.form.lastName.$model"
                          disabled
                          label="Last Name *"
                          maxlength="50"
                        >
                          <template #label>
                            Last Name
                            <span class="red">*</span>
                          </template></v-text-field
                        >

                        <div
                          class="invalid-feedback"
                          v-if="$v.form.lastName.$error"
                        >
                          <span v-if="$v.form.lastName.$error"
                            >Last Name is required</span
                          >
                        </div>
                      </div>
                      <!-- uci -->
                      <div
                        class="col-md-6 mb-form"
                        :class="{ invalid: $v.form.uci.$error }"
                      >
                        <v-text-field
                          v-model.trim="$v.form.uci.$model"
                          v-if="isEditAble"
                          label="UCI"
                          type="number"
                          :max="7"
                          maxlength="7"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        >
                          <template #label>
                            UCI
                            <span class="red">*</span>
                          </template></v-text-field
                        >
                        <v-text-field
                          v-else
                          disabled
                          v-model.trim="$v.form.uci.$model"
                          label="UCI"
                          type="number"
                          ><template #label>
                            UCI
                            <span class="red">*</span>
                          </template></v-text-field
                        >
                        <div class="invalid-feedback" v-if="$v.form.uci.$error">
                          <span v-if="!$v.form.uci.maxLength"
                            >Uci should be less than 7</span
                          >
                          <span v-else-if="$v.form.uci.$error"
                            >uci is required</span
                          >
                        </div>
                      </div>
                      <!-- dob -->
                      <div
                        class="col-md-6 mb-form"
                        :class="{ invalid: $v.form.dob.$error }"
                      >
                        <label>DOB</label> <span class="red">*</span>

                        <date-picker
                          :disabled="!isEditAble"
                          ref="dp1"
                          @focusin.native="onfocusin"
                          placeholder="Choose Birth Date"
                          :config="datePickerOptions"
                          v-model.trim="$v.form.dob.$model"
                        ></date-picker>

                        <div class="invalid-feedback" v-if="$v.form.dob.$error">
                          <span v-if="$v.form.dob.$error">Dob is required</span>
                        </div>
                      </div>
                      <div class="col-md-6 mb-form">
                        <div>
                          <label>Siblings</label>
                          <v-select
                            v-if="!isEditAble"
                            disabled
                            v-model="clientFamilyId"
                            :items="clientSiblingList"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            persistent-hint
                            multiple
                          ></v-select>
                          <v-autocomplete
                            v-else
                            v-model="clientFamilyId"
                            :items="clientSiblingList"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '200' }"
                            label="Siblings"
                            chips
                            multiple
                            @change="limiteSiblings"
                          ></v-autocomplete>
                        </div>
                      </div>
                      <div class="col-md-6 mb-form">
                        <div :class="{ invalid: $v.form.joinDate.$error }">
                          <label>Start of Care</label>
                          <date-picker
                            :disabled="!isEditAble"
                            ref="dp1"
                            @focusin.native="onfocusin"
                            placeholder="Start of Care"
                            :config="datePickerOptions2"
                            v-model.trim="$v.form.joinDate.$model"
                          ></date-picker>

                          <div
                            class="invalid-feedback"
                            v-if="$v.form.joinDate.$error"
                          >
                            <span v-if="$v.form.joinDate.$error"
                              >Join Date is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 mb-form" v-if="isTerminated">
                        <div>
                          <label>Termination Date</label>
                          <date-picker
                            :disabled="!isEditAble"
                            ref="dp1"
                            @focusin.native="onfocusin"
                            placeholder="Termication Date"
                            :config="datePickerOptions"
                            v-model="form.terminationDate"
                          ></date-picker>

                          <div
                            class="invalid-feedback"
                            v-if="$v.form.joinDate.$error"
                          >
                            <span v-if="$v.form.joinDate.$error"
                              >Termination Date is required</span
                            >
                          </div>
                        </div>
                      </div>
                    </v-row>

                    <v-divider></v-divider>

                    <div class="row">
                      <div class="col-md-6">
                        <h3 class="ml-2">
                          Location <span class="red">*</span>
                        </h3>
                        <span v-if="!isEditAble">
                          <v-text-field
                            v-model.trim="$v.form.address.$model"
                            disabled
                            maxlength="100"
                          >
                          </v-text-field
                        ></span>
                        <span v-else>
                          <div :class="{ invalid: $v.form.address.$error }">
                            <gmap-autocomplete
                              style="
                                width: 100%;
                                border-style: solid;
                                height: 40px;
                              "
                              :placeholder="form.address"
                              :value="form.address"
                              @place_changed="setPlace"
                            >
                            </gmap-autocomplete>
                            <div
                              class="invalid-feedback"
                              v-if="$v.form.address.$error"
                            >
                              <span v-if="$v.form.address.$error"
                                >Address is required</span
                              >
                            </div>
                            <!-- <span class="ml-5">
                            <a class="btn btn-success mr-3" @click="usePlace">
                              <i class="material-icons">add</i> <span>Add</span>
                            </a></span
                          > -->
                          </div>
                        </span>
                      </div>
                      <!-- <div class="col-md-3">
                        <span>Unit/Apt Number</span>
                        <br />
                        <input
                          type="text"
                          name=""
                          :disabled="!isEditAble"
                          v-model="form.otherAddress"
                          id=""
                          placeholder="Unit/Apt Number"
                          style="
                            margin-top: 10px;
                            width: 20%;
                            border-style: solid;
                            height: 40px;
                          "
                        />
                       
                      </div> -->
                    </div>
                    <div>
                      <AddGoogleMap
                        :addressMap="this.addressMap"
                        @setAddress="address"
                      />
                    </div>
                  </div>
                  <div class="pb-5" data-wizard-type="step-content">
                    <div v-if="this.clientId == '' || this.clientId == null">
                      <center style="color: red">
                        Please Fill Client Form first
                      </center>
                    </div>
                    <div v-else><intake-client></intake-client></div>
                  </div>
                  <div class="pb-5" data-wizard-type="step-content">
                    <div v-if="this.clientId == '' || this.clientId == null">
                      <center style="color: red">
                        Please Fill Client Form first
                      </center>
                    </div>
                    <div v-else>
                      <authorization></authorization>
                    </div>
                  </div>
                  <div class="pb-5" data-wizard-type="step-content">
                    <div v-if="this.clientId == '' || this.clientId == null">
                      <center style="color: red">
                        Please Fill Client Form first
                      </center>
                    </div>
                    <div v-else>
                      <staffing></staffing>
                    </div>
                  </div>
                  <div class="pb-5" data-wizard-type="step-content">
                    <div v-if="this.clientId == '' || this.clientId == null">
                      <center style="color: red">
                        Please Fill Client Form first
                      </center>
                    </div>
                    <div v-else>
                      <task></task>
                    </div>
                  </div>
                  <div class="pb-5" data-wizard-type="step-content">
                    <div v-if="this.clientId == '' || this.clientId == null">
                      <center style="color: red">
                        Please Fill Client Form first
                      </center>
                    </div>
                    <div v-else>
                      <contact></contact>
                    </div>
                  </div>
                  <div class="pb-5" data-wizard-type="step-content"></div>
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase ml-5 px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Previous
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="Close"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4 mr-5 mb-3"
                      >
                        Close
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 mr-5 mb-3 py-4"
                        data-wizard-type="action-next"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import authorization from "./authorization";
import staffing from "./staffing";
import task from "./task";
import contact from "./contact";
import intakeClient from "./Intake";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";
import AddGoogleMap from "./AddGoogleMap";
import { fetchApi } from "../core/services/request";
var today = new Date();
export default {
  mixins: [validationMixin],
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      uci: { required, maxLength: maxLength(7) },
      dob: { required },
      address: { required },
      status: { required },
      payersId: { required },
      joinDate: { required },
    },
  },
  components: {
    AddGoogleMap,
    intakeClient,
    authorization,
    staffing,
    task,
    contact,
  },
  props: {
    action: String,
    ID: String,
  },
  data() {
    const now = new Date();
    return {
      currentPlace: null,
      datePickerOptions: {
        format: "MM/DD/YYYY",
        maxDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        ),
        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
      },
      datePickerOptions2: {
        format: "MM/DD/YYYY",
        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
      },
      isSaving: false,
      modelcloseable: false,
      clientSiblingList: [],
      isUpdating: false,
      clientFamilyId: [],
      fields: [
        {
          key: "firstName",
          label: "First Name",
        },
        {
          key: "lastName",
          label: "Last Name",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "uci",
          label: "UCI",
        },
        {
          key: "relation",
          label: "Relation",
        },
      ],

      isSubmitting: false,
      currentPage: 1,
      perPage: 50,
      isClientexist: false,
      isEditAble: true,
      payers: null,
      max: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
      addressMap: {
        longitude: "",
        latitude: "",
      },
      payersList: [],
      form: {
        image: "",
        firstName: "",
        lastName: "",
        email: "",
        uci: "",
        dob: "",
        address: "",
        otherAddress: "",
        state: "",
        city: "",
        zip: "",
        status: "",
        longitude: "",
        latitude: "",
        payersId: "",
        joinDate: null,
        terminationDate: null,
        clientsSiblings: [],
      },
      statusdrpdown: [
        { value: "", text: "Please select status" },
        { value: "Active", text: "Active" },
        { value: "Inactive", text: "Inactive" },
        { value: "Terminated", text: "Terminated" },
      ],
      url: null,
      clientId: null,
      image: null,
      isTerminated: false,
    };
  },
  mounted() {
    if (this.action == "Preview" && this.editClient == null) {
      this.$store.dispatch("editClient", this.ID).then((res) => {
        this.action = "Preview";
        this.isfetching = false;
        this.$bvModal.show("createuser");
        if (this.action == "Preview" && this.editClient != null) {
          this.isClientexist = true;
          this.url = this.editClient.image;
          this.modelcloseable = true;
          this.form = this.editClient;
          this.form.terminationDate =
            this.editClient.terminationDate != null &&
            this.dobformat(this.editClient.terminationDate);
          this.form.dob = this.dobformat(this.form.dob);
          this.form.joinDate = this.dobformat(this.form.joinDate);
          this.clientId = this.editClient.id;
          this.isEditAble = false;
          this.addressMap.longitude = this.form.longitude;
          this.addressMap.latitude = this.form.latitude;
          this.fetchData().catch((error) => {});
          if (this.form.clientsSiblings.length > 0) {
            this.form.clientsSiblings.forEach((e) => {
              this.clientFamilyId.push(e.clientFamilyId);
            });
          }
        }
        this.getStatus();

        this.$store.dispatch("getPayersList").then((res) => {
          if (this.GetAuthenticatedUser.userType == "JFS") {
            this.payersList = this.PayersList.filter(
              (x) => x.companyName == "JFS"
            );
            this.form.payersId = this.payersList[0].id;
          } else if (this.GetAuthenticatedUser.userType == "Home Health") {
            this.payersList = this.PayersList.filter(
              (x) => x.companyName == "Regional Center/Medi-Cal"
            );
            this.form.payersId = this.payersList[0].id;
          } else {
            this.payersList = this.PayersList; 
          }
        });
        this.$store.dispatch("getClientList");
      });
    } else {
      if (this.action == "Preview" && this.editClient != null) {
        this.isClientexist = true;
        this.url = this.editClient.image;
        this.modelcloseable = true;
        this.form = this.editClient;
        this.form.terminationDate =
          this.editClient.terminationDate != null &&
          this.dobformat(this.editClient.terminationDate);
        this.form.dob = this.dobformat(this.form.dob);
        this.form.joinDate = this.dobformat(this.form.joinDate);
        this.clientId = this.editClient.id;
        this.isEditAble = false;
        this.addressMap.longitude = this.form.longitude;
        this.addressMap.latitude = this.form.latitude;
        this.fetchData().catch((error) => {});
        if (this.form.clientsSiblings.length > 0) {
          this.form.clientsSiblings.forEach((e) => {
            this.clientFamilyId.push(e.clientFamilyId);
          });
        }
        if (this.form.status == "Terminated") {
          this.isTerminated = true;
        }
      }

      this.$store.dispatch("getPayersList").then(() => {
        if (this.GetAuthenticatedUser.userType == "JFS") {
          this.payersList = this.PayersList.filter(
            (x) => x.companyName == "JFS"
          );
          this.form.payersId = this.payersList[0].id;
        } else if (this.GetAuthenticatedUser.userType == "Home Health") {
          this.payersList = this.PayersList.filter(
            (x) => x.companyName == "Regional Center/Medi-Cal"
          );
          this.form.payersId = this.payersList[0].id;
        } else {
          this.payersList = this.PayersList; 
        }
      });
      this.$store.dispatch("getClientList");
    }
    this.clientSiblingList = this.ClientsList.filter((x) => {
      return x.id != this.clientId;
    });
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard", route: "wizard-1" },
      { title: "Wizard-3" },
    ]);

    const wizard = new KTWizard("kt_wizard_v3", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });
    wizard.on("beforeNext", function (/*wizardObj*/) {
      this.$v.$touch();
    });

    // Change event
    wizard.on("change", function () {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  computed: {
    TerminationDate() {
      if (this.form.status !== "Terminated") return;

      var date = this.dobformat(this.form.terminationDate);
      return date;
    },
    payerName() {
      return this.PayersList.find((x) => x.id == this.form.payersId);
    },
    formId() {
      return this.clientId;
    },
    statusColor() {
      var color = "";
      switch (this.form.status) {
        case "Active":
          color = "green";
          break;
        case "Inactive":
          color = "#ffca00";
          break;
        case "New Referral":
          color = "#2255e6";
          break;
        case "Pending Termination":
          color = "orange";
          break;
        case "Terminated":
          color = "red";
      }
      return color;
    },
    statusBackGroundColor() {
      var color = "";
      switch (this.form.status) {
        case "Active":
          color = "#e8fff3";
          break;
        case "Inactive":
          color = "#fff8dd";
          break;
        case "New Referral":
          color = "#f8f5ff";
          break;
        case "Pending Termination":
          color = "#f7dfb5";
          break;
        case "Terminated":
          color = "#fff5f8";
      }
      return color;
    },
    ClientId() {
      return this.clientId;
    },
    ...mapGetters([
      "editClient",
      "PayersList",
      "ClientsList",
      "GetAuthenticatedUser",
    ]),
  },
  methods: {
    getStatus() {
      if (this.form.status == "Terminated") {
        var date = new Date();

        this.form.terminationDate = new Date(
          `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        );

        this.isTerminated = true;
      } else {
        this.isTerminated = false;
      }
    },
    limiteSiblings() {
      if (this.clientFamilyId.length > 3) this.clientFamilyId.pop();
    },
    usePlace() {
      this.addressMap = this.currentPlace;
      this.form.latitude = this.currentPlace.geometry.location.lat().toString();
      this.form.longitude = this.currentPlace.geometry.location
        .lng()
        .toString();
      this.form.address = this.currentPlace.formatted_address;
    },
    setPlace(place) {
      this.currentPlace = place;
      this.addressMap = place;
      this.form.latitude = place.geometry.location.lat().toString();
      this.form.longitude = place.geometry.location.lng().toString();
      this.form.address = place.formatted_address;
    },
    dobformat(obj) {
      var date = new Date(obj);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "-" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "-" +
        date.getFullYear() +
        " " +
        date.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
      );
    },
    foramtDateHeading(obj) {
      if (obj) {
        let d = new Date(typeof obj == "string" ? obj.replace(/-/g, "/") : obj);
        let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
        let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
        let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
        return `${mo}-${da}-${ye}`;
      }
    },
    onfocusin() {},
    resetForm() {
      (this.form.firstName = ""),
        (this.form.lastName = ""),
        (this.form.email = ""),
        (this.form.uci = 0),
        (this.form.password = ""),
        (this.form.dob = null),
        (this.form.joinDate = null),
        (this.form.address = ""),
        (this.form.otherAddress = ""),
        (this.form.state = ""),
        (this.form.city = ""),
        (this.form.zip = ""),
        (this.form.status = true),
        (this.form.longitude = ""),
        (this.form.latitude = ""),
        (this.form.diagnosis = ""),
        (this.form.careWorkerOverTime = false),
        (this.form.notesMandatory = false),
        (this.form.distanceExempt = false),
        (this.form.signatureRequired = false),
        (this.form.payersId = ""),
        (this.form.image = ""),
        (this.form.siblings = []);
    },
    async fetchData() {
      if (this.clientId) {
        await this.$store
          .dispatch("getSiblings", {
            pageNo: this.currentPage,
            pageSize: this.perPage,
            clientId: this.clientId,
          })
          .then()
          .catch((ex) => {});

        await this.$store
          .dispatch("getClientInfo", this.clientId)
          .then((response) => {})
          .catch((ex) => {});
      }
    },
    address(obj) {
      this.form.longitude = obj.lng.toString();
      this.form.latitude = obj.lat.toString();
    },
    submit: function (e) {
      e.preventDefault();
    },
    Close: function (e) {
      e.preventDefault();
      this.$emit("clientSent");
    },
    submitClient() {
      this.$v.$touch();
      this.form.clientsSiblings = [];
      this.clientFamilyId.forEach((e) => {
        this.form.clientsSiblings.push({ clientFamilyId: e });
      });
      if (!this.$v.form.$invalid) {
        this.isSaving = true;
        if (this.clientId) {
          this.isUpdating = true;
          this.form.dob = new Date(this.form.dob);
          this.form.dob.setHours(this.form.dob.getHours() + 13);
          this.form.joinDate = new Date(this.form.joinDate);
          this.form.joinDate.setHours(this.form.joinDate.getHours() + 13);
          this.form.terminationDate = new Date(this.form.terminationDate);
          this.form.terminationDate.setHours(
            this.form.terminationDate.getHours() + 13
          );
          this.$store
            .dispatch("updateClient", this.form)
            .then(() => {
              this.isSaving = false;
              this.isUpdating = false;
              this.isClientexist = true;
              this.isEditAble = false;
              Swal.fire({
                title: "",
                text: "The Client has been Updated Successfully!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
            })
            .catch(() => {
              this.isUpdating = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
              this.isSaving = false;
            });
        } else {
          this.form.dob = new Date(this.form.dob);
          // var dob = this.form.dob.toLocaleString("en-US", {
          //   timeZone: "America/Los_Angeles",
          // });
          // this.form.dob = new Date(dob);

          this.form.joinDate = new Date(this.form.joinDate.replace(/-/g, "/"));
          // var joinDate = this.form.dob.toLocaleString("en-US", {
          //   timeZone: "America/Los_Angeles",
          // });
          // this.form.joinDate = new Date(joinDate);
          this.$store
            .dispatch("saveClient", this.form)
            .then(async (response) => {
              if (response.message == "Success") {
                await this.$store.dispatch("editClient", response.data);
                this.action == "Preview";
                this.isClientexist = true;
                this.isEditAble = false;
                this.clientId = response.data;
                this.isSaving = false;
                this.fetchData();
                Swal.fire({
                  title: "",
                  text: "The Client has been Created Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
              } else {
                this.isSaving = false;
                Swal.fire({
                  title: "",
                  text: response.validationMessage,
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
            })
            .catch((ex) => {
              this.isSaving = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        }
        this.fetchData();
      }
    },
    Preview_image() {
      var id = this.clientId;
      var file = document.querySelector("input[type=file]").files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        this.url = e.target.result;
        fetchApi(
          "api/Client/UploadClientProfileImage",
          { id: id, image: this.url },
          "Post",
          { "Content-Type": "application/json" }
        );
      };
      reader.onerror = function (error) {
        alert(error);
      };
      reader.readAsDataURL(file);
      this.url = URL.createObjectURL(this.image);

      const reader2 = new FileReader();
      reader2.onloadend = () => {
        this.form.image = reader2.result;
      };
      reader2.readAsDataURL(file);
    },
    Delete() {
      var id = this.clientId;
      Swal.fire({
        icon: "error",
        text: "Are you sure you want to remove?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
      }).then((e) => {
        if (e.isConfirmed) {
          fetchApi(
            `api/Client/RemoveClientProfileImage?id=${id}`,
            null,
            "Post",
            {
              "Content-Type": "application/json",
            }
          ).then((response) => {
            if (response.message == "Success") {
              this.url = null;
            }
          });
        }
      });

      // fetchApi(`api/Client/RemoveClientProfileImage?id=${id}`, null, "Post", {
      //   "Content-Type": "application/json",
      // }).then((response) => {
      //   if (response.message == "Success") {
      //     this.url = null;
      //   }
      // });
    },
    editClientinfo() {
      this.isEditAble = true;
    },
  },
  destroyed() {
    this.$store.dispatch("emptyEditClient", null);
  },
};
</script>
<style scoped>
.wizard.wizard-3.wizard-nav.wizard-steps.wizard-step[data-wizard-state="current"] {
  background-color: #f69463;
}
.wizard.wizard-3
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="current"]
  .wizard-label {
  color: #f69463 !important;
}
.wizard.wizard-3
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="current"]
  .wizard-label
  .wizard-bar:after {
  width: 100%;
  background-color: #f69463;
}
.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
.red {
  color: red;
}
.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
</style>
