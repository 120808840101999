<template>
  <div data-app>
    <v-form
      class="form mt-0 mt-lg-10"
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <div
        v-if="this.editauthorize"
        class="page-title pageheading"
        style="height: 50px"
      >
        <span class="float-right green-btn">
          <a
            v-if="isEditAble"
            class="btn btn-success mr-3"
            @click="editClientinfo"
          >
            <i class="far fa-eye edit_btn"></i><span>Edit</span></a
          >
          <a v-else class="btn btn-success mr-3" @click="submit">
            <i class="far fa-eye edit_btn"></i
            ><span
              >Update
              <span v-if="isSubmitting">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular> </span></span
          ></a>
        </span>
      </div>
      <v-row class="p-2">
        <v-col cols="12" sm="6">
          <label> Payers</label>
          <b-form-select
            class="form-control"
            disabled
            v-model="editClient.payersId"
            :rules="['Required']"
            required
            value-field="id"
            text-field="companyName"
            :options="PayersList"
          ></b-form-select>
        </v-col>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.status.$error }"
        >
          <label> Status </label><span class="red"> *</span>
          <b-form-select
            :disabled="isEditAble"
            class="form-control"
            v-model.trim="$v.form.status.$model"
            :rules="['Required']"
            :options="status"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.status.$error">
            <span v-if="$v.form.status.$error">Status is required</span>
          </div>
        </div>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.authorizationNumber.$error }"
        >
          <v-text-field
            :disabled="isEditAble"
            v-model.trim="$v.form.authorizationNumber.$model"
            label="Authorization Number *"
            minlength="8"
            maxlength="8"
            ><template #label>
              Authorization Number
              <span class="red"> *</span></template
            ></v-text-field
          >
          <div
            class="invalid-feedback"
            v-if="$v.form.authorizationNumber.$error"
          >
            <span v-if="$v.form.authorizationNumber.$error"
              >Authorization Number is required</span
            >
          </div>
        </div>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.caseWorkerName.$error }"
        >
          <label>Case Worker </label> <span class="red"> *</span>
          <v-text-field
            v-model="form.caseWorkerName"
            label="Case worker"
            :disabled="isEditAble"
            maxlength="250"
          ></v-text-field>
          <div class="invalid-feedback" v-if="$v.form.caseWorkerName.$error">
            <span v-if="$v.form.caseWorkerName.$error"
              >Case Worker is required</span
            >
          </div>
          <!-- <b-form-select
            v-if="isEditAble"
            :disabled="isEditAble"
            class="form-control"
            v-model.trim="$v.form.caseWorkerId.$model"
            value-field="id"
            text-field="name"
            :options="caseWorkerList"
          ></b-form-select>
          <model-list-select
            v-else
            :list="caseWorkerList"
            v-model.trim="$v.form.caseWorkerId.$model"
            option-value="id"
            option-text="name"
            placeholder="Select Case Worker"
            :disabled="isEditAble"
          >
          </model-list-select> -->
        </div>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.vendorId.$error }"
        >
          <label>Vendor Code </label><span class="red"> *</span>
          <b-form-select
            :disabled="isEditAble"
            class="form-control"
            v-model.trim="$v.form.vendorId.$model"
            @change="vendorselect()"
            :rules="['Required']"
            value-field="id"
            text-field="vendorCode"
            :options="vendorList"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.vendorId.$error">
            <span v-if="$v.form.vendorId.$error">Vendor is required</span>
          </div>
        </div>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.subCodesId.$error }"
        >
          <label>Service </label><span class="red"> *</span>
          <b-form-select
            v-if="!isVendorIdExist"
            class="form-control"
            :disabled="true"
            v-model.trim="$v.form.subCodesId.$model"
            :rules="['Required']"
            value-field="id"
            text-field="subCode"
            :options="subcodeList"
          ></b-form-select>
          <b-form-select
            v-else
            :disabled="isEditAble"
            class="form-control"
            @change="subcodeselect()"
            v-model.trim="$v.form.subCodesId.$model"
            :rules="['Required']"
            value-field="id"
            text-field="subCode"
            :options="subcodeList"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.subCodesId.$error">
            <span v-if="$v.form.subCodesId.$error">SubCode is required</span>
          </div>
        </div>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.startDate.$error }"
        >
          <label>Start Date </label><span class="red"> *</span>
          <date-picker
            ref="dp1"
            :disabled="isEditAble"
            @focusin.native="onfocusin"
            placeholder="Choose Start Date"
            @dp-change="dataStartChange"
            :config="datePickerOptions"
            v-model.trim="$v.form.startDate.$model"
          ></date-picker>
          <div class="invalid-feedback" v-if="$v.form.startDate.$error">
            <span v-if="$v.form.startDate.$error">Start Date is required</span>
          </div>
        </div>

        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.endDate.$error }"
        >
          <label>End Date </label><span class="red"> *</span> <br />
          <date-picker
            ref="dp1"
            :disabled="isEditAble"
            @focusin.native="onfocusin"
            class="dpdataEndDate"
            placeholder="Choose End Date"
            :config="datePickerOptionsEND"
            v-model.trim="$v.form.endDate.$model"
          ></date-picker>
          <div class="invalid-feedback" v-if="$v.form.endDate.$error">
            <span v-if="$v.form.endDate.$error">End Date is required</span>
          </div>
        </div>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="form.rate"
            label="RATE"
            disabled
            maxlength="50"
          ></v-text-field>
        </v-col>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.type.$error }"
        >
          <label>Type </label><span class="red"> *</span>

          <!-- @change="dailyselect()" -->
          <b-form-select
            class="form-control"
            v-model.trim="$v.form.type.$model"
            :disabled="isEditAble"
            :rules="['Required']"
            :options="Type"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.type.$error">
            <span v-if="$v.form.type.$error">Type is required</span>
          </div>
        </div>
        <!-- <v-col v-if="form.type == 'Daily'" cols="12" sm="12">
          <v-row>
            <v-col cols="12" sm="1">
              <v-text-field
                :disabled="isEditAble"
                v-model="form.monday"
                label="Mon"
                type="number"
                maxlength="2"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              
              <v-text-field
                :disabled="isEditAble"
                label="Tue"
                v-model="form.tuesday"
                type="number"
                maxlength="2"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field
                :disabled="isEditAble"
                label="Wed"
                v-model="form.wednesday"
                type="number"
                maxlength="2"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field
                :disabled="isEditAble"
                v-model="form.thursday"
                label="Thur"
                type="number"
                maxlength="2"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              ></v-text-field>
            </v-col>
            
            <v-col cols="12" sm="1"> </v-col>
            <v-col cols="12" sm="1"> </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                :disabled="isEditAble"
                label="Non Suc"
                v-model="form.nonSuc"
                type="number"
                maxlength="2"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1"> </v-col>
          </v-row>
        </v-col> -->
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.hours.$error }"
        >
          <label>Hours Per Month </label><span class="red"> *</span>
          <v-text-field
            v-model.trim="$v.form.hours.$model"
            label="Hours Per Month"
            type="number"
            :disabled="isEditAble"
            maxlength="3"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          ></v-text-field>
          <div class="invalid-feedback" v-if="$v.form.hours.$error">
            <span v-if="$v.form.hours.$error">
              {{
                form.hours < 1
                  ? "Hours should be greater than 0"
                  : "Hours are required"
              }}
            </span>
          </div>
        </div>

        <v-col
          v-if="form.vendorId == 'dfc02ce2-3ec1-488d-ec77-08da56f4b62a'"
          cols="12"
          sm="1"
        >
          <label>20</label>
          <v-text-field
            :disabled="isEditAble"
            v-model="form.thursday"
            type="number"
            maxlength="3"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          ></v-text-field>
        </v-col>
        <v-col
          v-if="form.vendorId == 'dfc02ce2-3ec1-488d-ec77-08da56f4b62a'"
          cols="12"
          sm="1"
        >
          <label>21</label>
          <v-text-field
            :disabled="isEditAble"
            v-model="form.friday"
            type="number"
            maxlength="3"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          ></v-text-field>
        </v-col>
        <v-col
          v-if="form.vendorId == 'dfc02ce2-3ec1-488d-ec77-08da56f4b62a'"
          cols="12"
          sm="1"
        >
          <label>22</label>
          <v-text-field
            :disabled="isEditAble"
            type="number"
            v-model="form.saturday"
            maxlength="3"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          ></v-text-field>
        </v-col>
        <v-col
          v-if="form.vendorId == 'dfc02ce2-3ec1-488d-ec77-08da56f4b62a'"
          cols="12"
          sm="1"
        >
          <label>23</label>
          <v-text-field
            v-model="form.sunday"
            type="number"
            maxlength="3"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            :disabled="isEditAble"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="12" sm="6">
          <v-text-field
            v-model.trim="$v.form.hours.$model"
            label="Hours"
            type="number"
            :disabled="isEditAble"
            maxlength="3"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          ></v-text-field>
          <div class="invalid-feedback" v-if="$v.form.type.$error">
            <span v-if="$v.form.type.$error">Type is required</span>
          </div>
        </v-col> -->
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="form.usageNotes"
            label="Usage Notes"
            :disabled="isEditAble"
            maxlength="250"
          ></v-text-field>
        </v-col>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.sharedServices.$error }"
        >
          <label>Shared Services </label><span class="red"> *</span>
          <b-form-select
            :disabled="isEditAble"
            class="form-control"
            v-model.trim="$v.form.sharedServices.$model"
            :rules="['Required']"
            :options="rollover"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.form.sharedServices.$error">
            <span v-if="$v.form.sharedServices.$error"
              >Roll Over is required</span
            >
          </div>
        </div>
        <v-col cols="12" v-if="form.sharedServices === 'yes'">
          <div class="form-group mb-form" :class="{ invalid: APError != '' }">
            <label>Associated Party </label>
            <b-form-select
              :disabled="isEditAble"
              class="form-control"
              v-model.trim="form.associatedParty"
              :rules="['Required']"
              text-field="name"
              value-field="id"
              :options="clients"
              @change="GetAuthorizations"
            ></b-form-select>
            <div class="invalid-feedback">
              <span>{{ APError }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" v-if="form.sharedServices === 'yes'">
          <div class="form-group col-md-6" :class="{ invalid: SAError != '' }">
            <label>Select Authorization </label>
            <b-form-select
              :disabled="isEditAble"
              class="form-control"
              v-model.trim="form.SharedAuthorization"
              :rules="['Required']"
              text-field="subCode"
              value-field="id"
              :options="authorizations"
            ></b-form-select>
            <div class="invalid-feedback" v-if="SAError != ''">
              <span>{{ SAError }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <label>Term Date</label>
          <date-picker
            :disabled="isEditAble"
            v-if="form.status == 'Terminated'"
            v-model="form.termDate"
            ref="dp1"
            @focusin.native="onfocusin"
            placeholder="Choose Term Date"
            :config="datePickerOptions"
          ></date-picker>

          <b-form-datepicker
            v-else
            v-model="form.termDate"
            disabled="disabled"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }"
            placeholder="Choose Term Date"
            locale="en"
          ></b-form-datepicker>
        </v-col>
      </v-row>

      <v-row v-if="!this.editauthorize" class="p-2">
        <v-col class="d-flex mt-4" cols="12" sm="6">
          <v-btn @click="submit">
            submit
            <span v-if="isSubmitting">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </span> </v-btn
        ></v-col>
      </v-row>
    </v-form>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength, minValue } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { ModelListSelect } from "vue-search-select";

var today = new Date();
export default {
  components: {
    ModelListSelect,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      status: { required },
      authorizationNumber: { required, minLength: minLength(8) },
      sharedServices: { required },
      caseWorkerName: { required },
      type: { required },
      hours: { required, minValue: minValue(1) },
      subCodesId: { required },
      endDate: { required },
      startDate: { required },
      vendorId: { required },
    },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  mounted() {
    this.reset();
    this.$store.dispatch("getVendorListbyPayerId", {
      payerId: this.editClient.payersId,
    });
    this.$store.dispatch("getSubCodeList");
    this.$store.dispatch("getClientList").then(() => {
      this.clients = this.ClientsList;
    });
    if (this.editauthorize) {
      this.form.modifiedBy = this.GetAuthenticatedUser.userName;
      this.isVendorIdExist = true;
      this.form = this.editauthorize;
      this.form.startDate = this.dobformat(this.form.startDate);
      this.form.endDate = this.dobformat(this.form.endDate);
      this.form.termDate = this.dobformat(this.form.termDate);
      this.form.rate = this.form.subCodes.rate; 
      this.form.sharedServices = this.editauthorize.sharedServices
        ? "yes"
        : "no";
      if (this.editauthorize.sharedServices) {
        this.$store
          .dispatch("getAuthorize", {
            pageNo: 1,
            pageSize: 1000000,
            clientId: this.editauthorize.associatedParty,
          })
          .then((response) => {
            if (response.message == "Success") {
              this.authorizations = this.authorization.filter(
                (x) => x.status === "Active"
              );
              this.form.SharedAuthorization =
                this.editauthorize.sharedAuthorization;
              this.totalItems = this.totalAuthorizeRow;
            }
          });
      }
    } else {
      this.isEditAble = false;
    }
  },
  computed: {
    ...mapGetters([
      "vendorList",
      "caseWorkerList",
      "PayersList",
      "subcodeList",
      "editClient",
      "editauthorize",
      "GetAuthenticatedUser",
      "ClientsList",
      "authorization",
    ]),
  },
  data() {
    return {
      clients: [],
      authorizations: [],
      SAError: "",
      APError: "",
      isEditAble: true,
      datePickerOptions: {
        format: "MM/DD/YYYY",

        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
      },
      datePickerOptionsEND: {
        format: "MM/DD/YYYY",
        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
      },
      isVendorIdExist: false,
      valid: true,
      isSubmitting: false,
      form: {
        authorizationNumber: 0,
        status: "",
        startDate: "",
        endDate: "",
        rate: 0,
        type: "",
        hours: 0,
        usageNotes: null,
        sharedServices: "no",
        SharedServicesParty: "",
        termDate: null,
        subCodesId: null,
        caseWorkerName: "",
        caseWorkerId: "",
        vendorId: "",
        clientId: "",
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0,
        modifiedBy: "",
        associatedParty: "",
        SharedAuthorization: "",
        previousAuthorizationNumber: 0,
      },
      casegiversAssociatesSubcodes: [],
      status: [
        { value: "", text: "Please select status" },
        { value: "Active", text: "Active" },
        { value: "Inactive", text: "Inactive" },
        { value: "Terminated", text: "Terminated" },
        { value: "Pending Termination", text: "Pending Termination" },
      ],
      rollover: [
        { value: "no", text: "No" },
        { value: "yes", text: "Yes" },
      ],
      Type: [
        { value: "", text: "Please Select Type" },
        { value: "Daily", text: "Daily" },
        { value: "Monthly", text: "Monthly" },
        { value: "Weekly", text: "Weekly" },
      ],
    };
  },
  methods: {
    dataStartChange: (e) => {
      document
        .querySelectorAll(".dpdataEndDate")[0]
        .__vue__.dp.minDate(e.date._d);

      var endDate = new Date(
        document.querySelectorAll(".dpdataEndDate")[0].value
      );
      var startDate = new Date(e.date._d);

      if (endDate <= startDate) {
        Swal.fire({
          title: "",
          text: "The End Date is less than Start Date!",
          icon: "error",
          confirmButtonClass: "btn btn-danger",
        });
        document.querySelectorAll(".dpdataEndDate")[0].value = null;
      }
    },
    editClientinfo() {
      this.isEditAble = false;
    },
    dobformat(obj) {
      if (obj != null) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    GetAuthorizations() {
      this.authorizations = [];
      this.$store
        .dispatch("getAuthorize", {
          pageNo: 1,
          pageSize: 1000000,
          clientId: this.form.associatedParty,
        })
        .then((response) => {
          if (response.message == "Success") {
            this.authorizations = this.authorization;
            this.totalItems = this.totalAuthorizeRow;
          }
        });
    },
    onfocusin() {},
    async submit() {
      this.SAError = "";
      this.APError = "";
      this.$v.$touch();
      if (this.$refs.form.validate() && this.$refs.form.validate()) {
        this.isSubmitting = true;
        this.form.authorizationNumber = parseFloat(
          this.form.authorizationNumber
        );
        this.form.rate = parseFloat(this.form.rate);
        this.form.hours = parseInt(this.form.hours);
        this.form.thursday = parseInt(this.form.thursday);
        this.form.friday = parseInt(this.form.friday);
        this.form.saturday = parseInt(this.form.saturday);
        this.form.sunday = parseInt(this.form.sunday);
        this.form.startDate = new Date(this.form.startDate);
        this.form.startDate.setHours(this.form.startDate.getHours() + 13);
        this.form.endDate = new Date(this.form.endDate);
        this.form.endDate.setHours(this.form.endDate.getHours() + 13);

        if (this.form.termDate != null) {
          this.form.termDate = new Date(this.form.termDate);
        }
        this.form.clientId = this.editClient.id;
        this.form.modifiedBy = this.GetAuthenticatedUser.userName;

        if (this.form.sharedServices === "yes") {
          
          if (
            this.form.associatedParty != "" &&
            this.form.SharedAuthorization != ""
          ) {
            var client = this.clients.find(
              (x) => x.id === this.form.associatedParty
            );
            var authorize = this.authorizations.find(
              (x) => x.id === this.form.SharedAuthorization
            ); 
            if(client && this.form.clientId == this.form.associatedParty){
              this.APError = "Please Change a value";
              this.isSubmitting = false;
              return
            }
            if (client && authorize) {
              this.form.SharedServicesParty = `${client.name} : ${authorize.subCode} Auth # ${authorize.authorizationNumber}`;

} 
          
           
          } else {
            if (this.form.associatedParty == "") {
              this.APError = "Please Select a value";
              this.isSubmitting = false;
              return
            }
            if (this.form.SharedAuthorization === "") {
              this.SAError = "Please Select a value";
              this.isSubmitting = false;
              return
            }
          }
        }
        this.form.sharedServices = this.form.sharedServices == "yes" ? true : false;
       
        if (this.form.id) {
              this.$store
                .dispatch("updateAuthorization", this.form)
                .then((response) => {
                  if (response.message == "Success") {
                    Swal.fire({
                      title: "",
                      text: "This authorization has been updated successfully.",
                      icon: "success",
                      confirmButtonClass: "btn btn-secondary",
                    });
                  } else if (response.message == "Failure") {
                    Swal.fire({
                      title: "",
                      text: response.validationMessage,
                      icon: "error",
                      confirmButtonClass: "btn btn-danger",
                    });
                  }
                  this.reset();
                  this.$emit("authorizeSent");
                  this.isSubmitting = false;
                })
                .catch((ex) => {
                  this.isSubmitting = false;
                });
            } else {
              if (this.form.hours > 0) {
                await this.$store
                  .dispatch("saveAuthorization", this.form)
                  .then((response) => {
                    if (response.message == "Success") {
                      Swal.fire({
                        title: "",
                        text: "The authorization has been created successfully.",
                        icon: "success",
                        confirmButtonClass: "btn btn-secondary",
                      });
                      if (this.casegiversAssociatesSubcodes.length > 0) {
                        var data = [];
                        this.casegiversAssociatesSubcodes.forEach((x) => {
                          var obj = {
                            assignCaregiverId: x.assignCaregiverId,
                            authorizationNumber: this.form.authorizationNumber,
                            payRate: x.payRate,
                            payRateType: x.payRateType,
                            subCodesId: x.subCodesId,
                          };
                          data.push(obj);
                        });

                        this.$store.dispatch(
                          "DuplicatingPreviousStaffing",
                          data
                        );
                      }
                      this.reset();
                      this.$emit("authorizeSent");
                    } else if (response.message == "Failure") {
                      Swal.fire({
                        title: "",
                        text: response.validationMessage,
                        icon: "error",
                        confirmButtonClass: "btn btn-danger",
                      });
                    }
                    this.isSubmitting = false;
                  })
                  .catch((ex) => {
                    this.isSubmitting = false;
                  });
              } else {
                this.isSubmitting = false;
              }
            }
      }
    },
    reset() {
      (this.form.authorizationNumber = 0),
        (this.form.status = ""),
        (this.form.startDate = ""),
        (this.form.endDate = ""),
        (this.form.rate = 0),
        (this.form.type = ""),
        (this.form.hours = 0),
        (this.form.usageNotes = null),
        (this.form.sharedServices = "no"),
        (this.form.termDate = null),
        (this.form.subCodesId = null),
        (this.form.caseWorkerId = ""),
        (this.form.vendorId = ""),
        (this.form.clientId = "");
    },
    subcodeselect() {
      // this.CheckPreviousAuthorization(this.form.subCodesId);
      this.form.rate = this.subcodeList.find(
        (x) => x.id == this.form.subCodesId
      ).rate;
    },
    // dailyselect() {
    //   if (this.form.type == "Daily") {
    //     this.form.usageNotes = "4H/D M-F ; 9H/NONSCH";
    //   }
    // },
    vendorselect() {
      var id = this.form.vendorId;
      this.form.subCodesId = "";
      this.form.rate = 0;
      if (id) {
        this.isVendorIdExist = true;
      }
      this.$store.dispatch("getSubCodeListByVendorId", { vendorId: id });
    },
    CheckPreviousAuthorization(subcodeId) {
      var obj = {
        subcodeId: subcodeId,
        clientId: this.editClient.id,
      };
      this.$store.dispatch("CheckPreviousAuthorization", obj).then((res) => {
        if (res.status) {
          Swal.fire({
            title: "",
            text: "Do you wish to use data from previously terminated Authorizations and add the new to staffing?",
            icon: "question",
            confirmButtonClass: "btn btn-secondary",
            cancelButtonClass: "btn btn-secondary",
            showCancelButton: true,
            confirmButtonText: "Save",
          }).then((result) => {
            if (result.isConfirmed) {
              var isExist = res.data.isExist[res.data.isExist.length - 1];
              this.form.startDate = this.dobformat(isExist.startDate);
              this.form.endDate = this.dobformat(isExist.endDate);
              this.form.hours = isExist.hours;
              this.form.type = isExist.type;
              this.form.thursday = isExist.thursday;
              this.form.friday = isExist.friday;
              this.form.saturday = isExist.saturday;
              this.form.sunday = isExist.sunday;
              this.form.usageNotes = isExist.usageNotes;
              this.form.sharedServices = isExist.sharedServices ? "yes" : "no";
              this.form.previousAuthorizationNumber =
                isExist.authorizationNumber;
              this.casegiversAssociatesSubcodes =
                res.data.caregiverAssociatedSubcodes;
            }
          });
        }
      });
    },
  },
};
</script>

<style>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
</style>