<template>
  <div>
   
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">INTAKE/ASSESSSMENT</span>
      </h3>

      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="editRecord">
          <i class="material-icons">add</i> <span>New Intake</span></a
        >
      </span>
    </div>

    <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        :fields="fields"
        striped
        hover
        select-mode="single"
        selectable
        @row-selected="onRowSelected"
      >
        <template #cell(actions)="row">
          <a @click="Edit(row.item)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </a>
          <a
            v-b-modal.confirmIntakeModal
            @click="sendInfo(row.item.id)"
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </a>
        </template> 
          <template #cell(dateTime)="row"> 
          {{dobformat(row.item.dateTime)}}
        </template>
      </b-table>
       <div class="row">
            <div class="col-6">
              <b-pagination
                size="md"
                      v-if="perPage != '-1'"
                :total-rows="totalItems"
                v-model="currentPage"
                :per-page="perPage"
              ></b-pagination>
            </div>
              <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
          </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <b-modal
      size="xl"
      id="createintake"
      hide-footer
      centered
      no-close-on-backdrop
      @ok="handleOk"
    >
      <template slot="modal-title">Add New INTAKE</template>
      <createwizard
        :clientIdprop="this.clientIdprop"
        @intakeSent="closeModal"
      ></createwizard>
    </b-modal>
    <b-modal
      centered
      text
      id="confirmIntakeModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="remove(intakeId)"
            ><span
              >YES I'M SURE
              <span v-if="isRemoving">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular> </span></span
          ></a>
        </span>
        <span class="green-btn">
          <a
            class="btn btn-danger mr-3"
            @click="$bvModal.hide('confirmIntakeModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
  </div>
</template>
<script>
import createwizard from "./intakeClient.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  components: {
    createwizard,
  },
  
  props: {
    clientIdprop: String,
  },
  data() {
    return {
      isfetching: false,
      isRemoving: false,
      search: "",
      intakeId: "",
      currentPage: 1,
      perPage: 50,
       totalItems: 0,
      showChangePass: true,
      items: [],
      fields: [
        {
          key: "intakeType",
          label: "Assessment Type",
        },
        {
          key: "dateTime",
          label: "Last Updated On",
        },
        {
          key: "lastUpdatedBy",
          label: "Last Updated By",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
    };
  },
  mounted() {
    this.fetchData().catch((error) => {
      console.error(error);
    });
  },
 watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    }, },
  computed: {
    ...mapGetters(["intakes", "totalIntakeRow", "editClient"]),
  },
  methods: {
     dobformat(obj) {
      var date = new Date(obj);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
     Search() {
      this.fetchData();
    },
    sendInfo(item) { 
      this.intakeId = item;
    },
    async fetchData() {
      if (this.editClient) {
        this.isfetching = true;
        var id = this.editClient.id; 
        await this.$store
          .dispatch("getIntakes", {
            pageNo: this.currentPage,
            pageSize: this.perPage,
            clientId: id,
          })
          .then((response) => {
            if (response.message == "Success") {
              this.isfetching = false;
              this.items = this.intakes;
              this.totalItems = this.totalIntakeRow;
            }
          })
          .catch((ex) => {
            this.isfetching = false;
            
          });
      }
    },
    onRowSelected(items) {
      this.Edit(items[0]);
    },
    Edit(obj) {
      if (obj != undefined) {
        this.$store.dispatch("editIntake", obj);
        this.$bvModal.show("createintake");
      }
    },
    editRecord() {
      this.$store.dispatch("editIntake", null);
      this.$bvModal.show("createintake");
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    closeModal() {
      this.$bvModal.hide("createintake");
      this.fetchData().catch((error) => {
        console.error(error);
      });
    },
    remove(obj) {
      this.isRemoving = true;
      this.$store
        .dispatch("removeIntakeClient", {
          id: obj,
        })
        .then((response) => {
           this.fetchData()
          this.$bvModal.hide("confirmIntakeModal");
          
          this.isRemoving = false;
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "The Intake has been Deleted Successfully!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        })
        .catch((ex) => {
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
          
        });
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.edit_btn{
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #FB8C00 !important;
};
.del_btn{
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #BF360C !important;
}
</style>
