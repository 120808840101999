<template>
  <div>
    <gmap-map
      :zoom="14"
      :center="center"
      style="width: 100%; height: 600px"
      @click="mark"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        @click="center = m.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AddGoogleMap",
  computed: {
    ...mapGetters(["editClient", "editEmployee"]),
  },
  data() {
    return {
      center: {},
      markers: [
        {
          position: {},
        },
      ],
      coordinates: null,
    };
  },
  props: {
    addressMap: Object,
  },
  watch: {
    addressMap: {
      handler: function () {
        this.setPlaceinMap();
      },
    },
  },
  mounted() {
    this.locateGeoLocation();
  },
  methods: {
    setPlaceinMap() {
      if (this.addressMap) {
        var newPostion = {
          lat: this.addressMap.geometry.location.lat(),
          lng: this.addressMap.geometry.location.lng(),
        };
        this.center = newPostion;
        this.markers[0].position = newPostion;
      }
    },
    mark(location) {
      this.coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.markers[0].position = this.coordinates;
      this.$emit("setAddress", this.coordinates);
    },
    locateGeoLocation: function () {
      if (this.editClient) {
        this.center = {
          lat: parseFloat(this.editClient.latitude),
          lng: parseFloat(this.editClient.longitude),
        };
        this.markers = [
          {
            position: {
              lat: parseFloat(this.editClient.latitude),
              lng: parseFloat(this.editClient.longitude),
            },
          },
        ];
      } else if (this.editEmployee) {
        this.center = {
          lat: parseFloat(this.editEmployee.latitude),
          lng: parseFloat(this.editEmployee.longitude),
        };

        this.markers = [
          {
            position: {
              lat: parseFloat(this.editEmployee.latitude),
              lng: parseFloat(this.editEmployee.longitude),
            },
          },
        ];
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.markers = [
            {
              position: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            },
          ];
        });
      }
    },
  },
};
</script>
<style>
.pac-container {
  z-index: 10000 !important;
  width: auto !important;
}
</style>
